$path	: '../../fonts/';

/*@font-face {
  font-family: 'BebasNeue';
  src:  url('#{$path}BebasNeue.eot');
  src:  url('#{$path}BebasNeue.eot?#iefix') format('embedded-opentype'),
  		url('#{$path}BebasNeue.otf')  format('opentype'),
	    url('#{$path}BebasNeue.woff') format('woff'),
	    url('#{$path}BebasNeue.ttf')  format('truetype'),
	    url('#{$path}BebasNeue.svg#BebasNeue') format('svg');
  font-weight: normal;
  font-style: normal;
}*/

@font-face {
    font-family: 'bebas_neue_regularregular';
    src: url('#{$path}bebasneue_regular-webfont.eot');
    src: url('#{$path}bebasneue_regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$path}bebasneue_regular-webfont.woff2') format('woff2'),
         url('#{$path}bebasneue_regular-webfont.woff') format('woff'),
         url('#{$path}bebasneue_regular-webfont.ttf') format('truetype'),
         url('#{$path}bebasneue_regular-webfont.svg#bebas_neue_regularregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

$fa-font-path: '../../fonts';
@import "fontawesome/font-awesome";
@import "fontawesome/font-awesome-ie7";
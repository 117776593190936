//##############################################################################
//                                                                             #
//	! 1.1 GLOBAL -> HELPERS/EXTRAS                                             #
//                                                                             #
//##############################################################################

.container {
    background-color: $white;
    box-shadow: 0 0 40px #aaa;
}


//##############################################################################
//                                                                             #
//	! 1.2 GLOBAL -> HEADER                                                     #
//                                                                             #
//##############################################################################

#header {
    .banner-image {
        height: 280px;

        img {
            height: 280px;
            margin-left: -583px;
        }
    }

    &.carousel-artsaward {
        .owl-item {
            height: 280px;

            img {
                height: 280px;
                margin-left: -583px;
            }
        }
    }

    #logo-full {
        top: 30px;
        width: 50%;
        margin-left: -25%;
    }

    .header-title {
        font-size: em(72);
    }
}


//##############################################################################
//                                                                             #
//	! 1.3 GLOBAL -> FOOTER                                                     #
//                                                                             #
//##############################################################################

#footer {
    .footer-sitemap {
        @include span-columns(10);
    }

    .footer-logo {
        @include span-columns(2);
    }

    .footer-support-links {
        @include span-columns(10);
    }
}

.copyright {
    padding-top: 20px;
    padding-bottom: 40px;

    .from,
    .with {
        @include span-columns(6);
        padding: 0;
    }

    .with {
        float: right;
        text-align: right;
    }

    p,
    img {
        margin-left: 20px;
    }

    .with p,
    .with img {
        margin-left: 0;
        margin-right: 20px;
    }

    .img2 {
        display: inline;
        margin-top: 0;
    }
}


//##############################################################################
//                                                                             #
//	! 2.1 NAV -> MAIN                                                          #
//                                                                             #
//##############################################################################

#nav-container {
    .container {
        box-shadow: none;
    }
}


//##############################################################################
//                                                                             #
//	! 2.2 NAV -> SUB                                                           #
//                                                                             #
//##############################################################################

#nav-sub,
.nav-sub {
    display: block;

    ul,
    .nav-sub-artsaward-homepage {
        margin: 10px 0 0;
    }

    .divider {
        display: inline-block;
        border-left: 2px solid lighten($primarycolor, 20%);
        margin-left: 10px;
        padding-left: 10px;

        &:before {
            content: none;
        }

        .templateAA & {
            border-left: 2px solid lighten($aa-primarycolor, 20%);
        }
    }

    li {
        margin-right: 0;
        margin-bottom: 10px;
    }

    &.climatecrisis {
        .divider {
            border-color: darken(#23b25c, 5%);
        }
    }
}


//##############################################################################
//                                                                             #
//	! 2.3 NAV -> SLIDEBAR                                                      #
//                                                                             #
//##############################################################################

#nav-side {
    .nav-divider,
    .nav-side-sub {
        display: none;
    }
}


//##############################################################################
//                                                                             #
//	! 3 CONTENT -> ASIDE                                                       #
//                                                                             #
//##############################################################################

.sidebar-col-1,
.sidebar-col-2 {
    float: none;
    width: auto;
    border-top: none;

    &:after {
        content: none;
    }
}

.block-aside,
.sidebar-col-2 .block-aside {
    @include span-columns(4);
    float: right;
    clear: right;
    border-top: $border;
}

.secondary-area .block-aside {
    float: none;
    width: auto;
}


//##############################################################################
//                                                                             #
//	! 3 CONTENT -> HUB                                                         #
//                                                                             #
//##############################################################################

.hub-intro {
    .hub-intro-links {
        a {
            font-size: em(28);
        }
    }

    .hub-intro-summary {
        p {
            font-size: em(16);
        }
    }
}


.menu__bubbles {
    .bubble__text {
        .bubble__title {
            font-size: 16px;
        }
    }

    .bubbles__spacer {
        width: 10px;

        .spacer__bubble {
            display: none;
        }

        .spacer__placeholder {
            width: 10px;
        }
    }

    .r-tabs-state-active {
        .bubble__text {
            .bubble__title {
                font-size: 24px;
            }
        }
    }
}


//##############################################################################
//                                                                             #
//	! 3 CONTENT -> INTRO                                                       #
//                                                                             #
//##############################################################################

.content-info-side {
    .img {
        width: auto;
        float: none;
    }

    p {
        background: none;
        width: auto;
        float: none;
        margin: 20px 0 0 0;
        padding: 0;
    }
}


//##############################################################################
//                                                                             #
//	! 3 CONTENT -> LAYOUT                                                      #
//                                                                             #
//##############################################################################

#content {
    &:after {
        content: "";
        right: 33.3333333333%;
    }
}

#content.hide-sidebar {
    &:after {
        right: 0;
    }
}

.content-info-side,
.secondary-area,
#ad-side {
    @include span-columns(4);
    float: right;
    clear: right;
}

.content-area {
    @include span-columns(8);

    &.area-full {
        @include span-columns(12);
    }
}

.sidebar__md {
    .content-info-side,
    .secondary-area,
    #ad-side {
        @include span-columns(3.5);
        float: right;
    }

    .content-area {
        @include span-columns(8.5);
    }

    #content:after {
        right: 29.1666666667%;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> ARTFORM ICONS                                             #
//                                                                             #
//##############################################################################

#menu-artform {
    border-top: $border;
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> BLOCKS                                                    #
//                                                                             #
//##############################################################################

.block-user {
    .item-thumb {
        img {
            width: 80px;
            height: 80px;
        }
    }
}

.block-map {
    border-top: $border;

    .map-area,
    .map-address {
        float: none;
        width: auto;
    }

    .map-area {
        padding-top: 100%;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> FILTER                                                    #
//                                                                             #
//##############################################################################

.filter-menu {
    .block-title & {
        margin-top: 4px;
        float: right;
    }

    .block-filter & {
        margin-top: 0;
        float: none;

        + .filter-menu {
            margin-top: 15px;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> LISTING                                                   #
//                                                                             #
//##############################################################################

.listing-image-grid {
    .post-title {
        font-size: em(18);
        height: 64px;
    }

    &.no-footer {
        .post-title {
            height: 107px;
        }
    }
}

.content-list.listing-image-grid {
    &.alt-videos {
        li {
            @include span-columns(4);
        }

        .i2 {
            .post-cont {
                &:after {
                    content: "";
                }
            }
        }

        .i3 {
            .post-cont {
                &:after {
                    content: none;
                }
            }
        }
    }
}


//##############################################################################
//                                                                             #
//	! 5.1 HOMEPAGE -> VOICE                                                    #
//                                                                             #
//##############################################################################

#features {
    .theme-info {
        @include span-columns(4);
        float: right;

        li {
            @include span-columns(4 of 4);
        }
    }

    .feature-content {
        li {
            @include span-columns(4);
        }
    }

    .feature-content .i2 a,
    .i4 a {
        &:after {
            content: "";
        }
    }

    .feature-content .i3 a,
    .feature-content .i6 a {
        &:after {
            content: none;
        }
    }
}

#from-community {
    .block-item {
        display: block;
    }

    .item-img img {
        display: none;
    }

    .item-img .item-type {
        position: static;
        padding: 1px 10px;
    }

    .item-summary {
        padding-left: 0;
    }
}

.row-video {
    @include span-columns(12);
    display: flex;
    align-items: stretch;
}

#featured-video {
    border-right: $border;
    flex: 0 0 50%;

    .item-thumb {
        margin-right: 0;
    }
}

#from-community {
    flex: 0 0 50%;
    border-top: 0;
}

#featured-voiceboxes {
    .owl-nav,
    .glide__arrows {
        display: block;
    }

    .item-list {
        overflow: hidden;
    }

    .glide__arrows {
        opacity: 0;
        transition: .5s;

        .glide__arrow--right {
            transform: translateX(50px);
            transition: .5s;
        }

        .glide__arrow--left {
            transform: translateX(-50px);
            transition: .5s;
        }
    }

    &:hover .glide__arrows {
        opacity: 1;

        .glide__arrow--right,
        .glide__arrow--left {
            transform: translateX(0px);
        }

        .glide__arrow--left.disabled {
            transform: translateX(-50px);
        }

        .glide__arrow--right.disabled {
            transform: translateX(50px);
        }
    }
}


//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> ARTS AWARD                                               #
//                                                                             #
//##############################################################################

#homepage-hubs {
    .item-wrap {
        @include span-columns(3);
    }

    .wrap-inner {
        height: 280px;
    }

    .hub-bronze {
        .wrap-inner {
            border-right: 1px solid #fff;
        }
    }
}

#homepage-aainfo {

    .aainfo-text {
        @include span-columns(3);

        .wrap-inner {
            float: left;
            height: 260px;
            border-right: 1px solid #fff;
        }
    }

    .aainfo-text-2 {
        .wrap-inner {
            border-right: none;
        }
    }

    .aainfo-video {
        @include span-columns(6);

        .wrap-inner {
            border-right: 1px solid #fff;
            position: relative;
            height: 300px;
            overflow: hidden;
        }

        img {
            position: absolute;
            left: 50%;
            margin-left: -375px;
            width: auto;
            height: 300px;
        }
    }
}

#homepage-aaposts {
    li {
        @include span-columns(3);
    }

    .i2 a {
        &:after {
            content: "";
        }
    }
}

#homepage-users {
    li,
    .i1 {
        @include span-columns(4);
    }

    .i1 a {
        &:after {
            content: "";
        }
    }
}

//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> TRINITY                                            #
//                                                                             #
//##############################################################################

.templateTR {
    #feature-boxes {
        .post-title {
            font-size: em(36);
        }
    }
}

#homepage-trqualifications {
    li {
        @include span-columns(3);
    }

    .i2 a {
        &:after {
            content: "";
        }
    }

    .post-title {
        font-size: em(20); //16
        height: 96px;
    }
}

#homepage-subjects {
    .item-wrap {
        @include span-columns(4);
    }

    .item-info {
        position: absolute;
        bottom: 28px;
    }

    .wrap-inner {
        height: 280px;
    }

    .hub-bronze {
        .wrap-inner {
            border-right: 1px solid #fff;
        }
    }
}

//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> MEDIA                                                    #
//                                                                             #
//##############################################################################

.homepage--media {

    .content-area {
        @include span-columns(7.2);
    }
    .sidebar-area {
        @include span-columns(4.8);
        margin-top: 20px;
    }
    #homepage-intro {
        display: flex;

        .intro-wrap {
            flex-basis: 66.66667%;
        }

        .search-wrap {
            flex-basis: 33.33333%;
            padding-top: 20px;
            padding-left: 40px;
        }
    }



    .slide-text-overlay h2 {
        width: 60%;
    }

    .splide__slide .post-title {
        margin-left: 0;
        margin-right: 0;
    }


    #media-posts-feed {

        .media-posts-filter {
            flex-direction: row;
            a.video-filter {
                width: 100%;
                margin-bottom: 0;
                margin-right: 10px;
            }
            a.audio-filter {
                width: 100%;
                margin-left: 10px;
            }
        }
    }
}

@mixin opacity($opacity: .5)
{
	opacity: $opacity;
	@if ($ie8-opacity-supported == true) {
		-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=#{$opacity * 100})";
	}
}

$imgpath: '../../img/';
$sprite: 'sprite.png';

@function img($file, $just_path: false)
{
	@if $just_path{
		@return "#{$imgpath}#{$file}";
	}
	@else{
		@return url('#{$imgpath}#{$file}');
	}
}

@mixin bg($file, $x:0, $y:0, $repeat:no-repeat)
{
	background-image: img($file);
	background-position: $x $y;
	background-repeat: $repeat;
}

@mixin sprite($x:-999em, $y:-999em)
{
	@include bg($sprite, $x, $y);
}

// Convert pixels to ems
// eg. for a relational value of 12px write em(12) when the parent is 16px
// if the parent is another value say 24px write em(12, 24)
$em-base: 16px !default;
@function em($pxval, $base: $em-base) {
  @if not unitless($pxval) {
    $pxval: strip-unit($pxval);
  }
  @if not unitless($base) {
    $base: strip-unit($base);
  }
  @return ($pxval / $base) * 1em;
}
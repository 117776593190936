.orgs__search{
	border-bottom: 1px solid $bordercolor;
	margin-top: -20px;

	.form__field label,
	.form__field--or span{
		font-family: $baseheadingfont;
		font-size: 18px;
		font-weight: normal;
		color: $textcolor;
	}

	.form__group{
		padding: 0 20px;
	}

	.form__field{
		margin-bottom: 20px;

		label small{
			color: $lightneutralcolor;
		}
	}

	.form__field--or{
		margin: -10px 0 7px;

		span {
			font-weight: bold;
			text-transform: uppercase;
		}
	}

	.selectize-input{
		font-family: $baseheadingfont;
		font-size: .82em;

		> input{
			border-radius: 0;
			vertical-align: baseline;
			font-size: 1em;
		}
	}

	.form__field .field__option{
		font-size: 16px;
		padding: 5px 0;
		margin-bottom: 5px;

		input{
			width: auto;
		}

		.switchery{
			margin-right: 5px;
		}
	}

	.form .row-submit
	{
		margin-bottom: 20px;

		.button {
			font-size: 18px;
			padding: 6px 22px;
			margin: 0 10px 0 0;
			vertical-align: top;
		}

		.button--outline{
			margin: 0;
			font-weight: normal;
			background: #fff;
			border: 1px solid $primarycolor;
			color: $primarycolor;
			text-transform: none;
			padding: 6px 12px 7px;
			font-size: 16px;

			.fa{
				margin-right: 10px;
				transform: scale(1.4);
			}
		}
	}
}

.orgs__results{
	.tabs__menu{
		font-family: $baseheadingfont;
		border-bottom: 1px solid $bordercolor;

		ul{
			list-style: none;
			padding: 0;
			margin: 0;
			display: flex;
		}

		a{
			display: inline-block;
			padding: 15px 25px;
			margin-bottom: -1px;
			outline: none;
			color: $lightneutralcolor;
			font-size: 18px;
			font-weight: bold;
			text-transform: uppercase;

			.fa{
				margin-right: 6px;
				transform: scale(1.2);
			}
		}

		li:first-child a{
			border-right: 1px solid $bordercolor;
		}

		.active a{
			background: #fff;
			border-right: 1px solid $bordercolor;
			color: $primarycolor;
		}
	}

	.tabs__panel{
		display: flex;
		flex-direction: column-reverse;
		border-top: none;

		* {
			box-sizing: border-box;
		}

		.map__results{
			width: 100%;
			padding: 20px;
			position: relative;

			.content-summary{
				.content-img{
					img{
						display: none;
					}

					.content-type{
						position: static;
						padding-left: 10px;
					}
				}
			}
		}

		.map__frame{
			width: 100%;
			height: 70vh;
			margin-top: 20px;

			iframe{
				width: 100%;
				height: 100%;
			}

			.gm-style-pbc {
				z-index: 5 !important;
				pointer-events: none;
			}
		}

		.list__results{
			width: 100%;
			padding: 20px;
		}
	}

	.tabs__panel--list{
		display: none;
	}

	.results__item{
		margin: 0 0 22px;
		padding: 0 0 10px;
		border-bottom: 1px solid $lightestneutralcolor;
		background: #fff;

		&:last-child,
		&.last{
			border-bottom: 0;
			padding-bottom: 0;
			margin-bottom: 0;
		}
	}
}

.content-summary{
	.content-img{
		position: relative;
		max-width: 200px;

		img{
			width: 100%;
		}

		.content-type{
			position: absolute;
			top: 10px;
			left: 0px;
			background: $primarycolor;
			color: $white;
			font-size: em(12);
			line-height: 1;
			padding: 2px 10px 2px 20px;
			text-transform: uppercase;

			&.alt{
				background: $aa-primarycolor;
			}
		}
	}

	.content-info{
		position: relative;
	}

	.content-date,
	.content-distance{
		display: block;
		margin: 4px 0 0;
		font-family: $baseheadingfont;
		font-size: em(13);
		color: $lightneutralcolor;
		text-transform: uppercase;
	}

	.content-distance{
		position: absolute;
		top: 0;
		right: 0;
		margin-top: 0;
	}

	&.content-summary--org{
		.content-distance{
			top: auto;
			bottom: 42px;
		}
	}

	.content-title{
		margin: 4px 0 0;
		font-family: $baseheadingfont;
		font-size: em(18);
		line-height: 1.3;
		font-weight: bold;
		color: $textcolor;

		a{
			color: $textcolor;
		}
	}

	.content-author{
		font-size: em(13);
		color: $lightneutralcolor;

		a{
			color: $lightneutralcolor;
		}
	}

	.content-text p,
	.content-address{
		margin: 6px 0 0 0;
		color: $neutralcolor;
		font-size: em(14);
	}

	.content-cta{
		display: inline-block;
		padding: 10px 0;
		text-transform: uppercase;
		font-size: em(14);
		font-weight: bold;
		font-family: $baseheadingfont;
		color: $primarycolor;

		.fa{
			margin-left: 5px;
		}
	}

}

.infoWindowContent{
	a{
		display: block;
	}
}

@media only screen and (min-width: 768px)
{
	.orgs__search{
		.form{
			display: flex;
			flex-direction: column;
		}

		.form__group{
			display: flex;
			padding: 0;
		}

		.form__field{
			flex: 1;
			padding: 0 20px;
		}

		.form__field--or{
			flex: 0;
			padding: 0;

			span{
				display: block;
				width: 24px;
				text-align: center;
				margin-top: 44px;
			}
		}

		.form__group--filter{
			.form__field--interest{
				padding-right: 20+12px;
			}

			.form__field--type{
				padding-left: 20+12px;
			}
		}

		.form__group--submit{
			margin-top: 20px;
			padding: 0 20px;
			justify-content: center;
		}
	}

	.orgs__results{
		.tabs__panel{
			flex-direction: row;

			.map__results{
				width: 30%;
				min-width: 300px;
				overflow-y: scroll;
			}

			.map__frame{
				width: auto;
				height: auto;
				flex: 1;
				margin-top: 0;
			}
		}

		.tabs__panel--map{
			height: 75vh;
			position: relative;

			&:before{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				z-index: 9;
				width: 30%;
				height: 20px;
				background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
			}
		}

		.list__results{
			max-width: 800px;
			margin: 20px auto;

			.results__item{
				display: flex;
			}

			.content-summary{
				.content-img{
					margin-bottom: 10px;
				}

				.content-distance{
					top: 0;
					bottom: auto;
				}

				.content-date{
					margin-top: 0;
				}

				.content-info{
					padding-left: 20px;
					flex: 1 0;
				}

				.content-title{
					font-size: em(24);
				}
			}
		}

		.list__results--location{
			.content-summary--org{
				.content-title{
					padding-right: 100px;
				}
			}
		}
	}

	.content-summary{
		.content-title{
			font-size: em(21);
		}
	}
}

@media only screen and (min-width: 1024px) {
	.orgs__search {
		.form {
			flex-direction: row;
			flex-wrap: wrap;
		}

		.form__group--search {
			order: 1;
			max-width: 700px;
			flex: 1;
		}

		.form__group--submit {
			order: 2;
			justify-content: flex-start;
			align-items: flex-end;
			margin-top: 0;
		}

		.form__group--filter {
			order: 3;
			width: 100%;
			margin: 20px 0 0;

			.form__field--interest{
				max-width: 298px;
			}

			.form__field--type{
				flex: 2.25;
			}
		}

		.form__field {
			label{
				padding-bottom: 12px;
			}

			.field__option{
				display: inline-block;
				margin-right: 30px;
				margin-bottom: 10px;
			}
		}

		.form__field--or{
			span{
				margin-top: 48px;
			}
		}

		.form .row-submit {
			.button {
				vertical-align: bottom;
			}
		}
	}
}
.glide {
    .glide__slides {
        margin: 0;
    }

    .no-js & {
        .glide__arrows {
            display: none !important;
        }

        .glide__slides{
            overflow-x: auto;
        }
    }
}
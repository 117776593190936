$media-queries-supported : true;
$ie8-opacity-supported 	 : false;

@import "bourbon/bourbon";

@import "config";

@import "neat/neat";

@import "partials/mixins";


@import "html5bp/normalize";
@import "html5bp/base";
@import "html5bp/helpers";

@import "partials/fonts";
@import "partials/custom_base";


//start ADD-ONS

@import "partials/slidebars";

@import "partials/owlcarousel";

@import "@glidejs/glide/src/assets/sass/glide.core";
@import "partials/glidejs";
//@import "@glidejs/glide/src/assets/sass/glide.theme";

$mfp-include-arrows:			false;
$mfp-include-image-type:		false;
$mfp-overlay-color:				$white;
$mfp-shadow:					none;
$mfp-controls-opacity:			1;
$mfp-inner-close-icon-color:	$white;
$mfp-popup-padding-left:		0px;
@import "partials/magnific";

$legacy-support-for-ie6:		false;
$legacy-support-for-ie7:		true;
@import "partials/formalize";

@import "partials/pickadate/classic";
@import "partials/pickadate/classic.date";
@import "partials/pickadate/custom";

@import "partials/responsive-tabs";

@import "partials/froala";

@import "partials/mailchimp";

@import "partials/selectize.theme";

@import "partials/switchery";

//end ADD-ONS


@import "main";

@import "partials/orgs_search";

@import "mq/media-queries";


@import "html5bp/print";

@import "partials/splide.min";

@import "partials/selectric";

//##############################################################################
//                                                                             #
//	! 1.1 GLOBAL -> HELPERS/EXTRAS                                             #
//                                                                             #
//##############################################################################

.no-js {
    .need-js {
        display: none !important;
    }
}


// https://imperavi.com/redactor/plugins/alignment/
// Redactor depreciated but keep these rules for legacy posts
.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}


#cookielaw {
    position: static;
    width: 100%;
    margin: 0;
    padding: 0;
    background: $primarycolor;

    .cookielaw {
        padding: 0 60px;
        position: relative;
    }

    .cookielaw-message {
        display: block;
        margin: 0;
        padding: .5em 1em;
        font-size: em(13);
        text-decoration: none;
        color: $white;
        text-align: center;

        span {
            text-decoration: underline;
        }
    }

    .cookielaw-close {
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 0px;
        width: 60px;
        font-family: sans-serif;
        text-align: center;
        cursor: pointer;
        border: none;
        background: $primarycolor;
        color: $white;
        font-size: em(24);
        line-height: 1;
        padding: 0 0 4px 0;
        border-radius: 0;

        &:hover {
            background: darken($primarycolor, 10%);
            color: $white;
        }

        .no-js & {
            display: none;
        }
    }
}


.container {
    @include outer-container;
    @extend .clearfix;
}

#page {
    @include span-columns(12);
}

.item-cta {
    text-transform: uppercase;
    font-size: em(14);
    font-weight: bold;
    font-family: $baseheadingfont;
    color: $primarycolor;

    i {
        margin-left: 2px;
    }
}

.xmas {
    #nav-sub,
    #nav-side {
        @include bg('main-v2.1/bg-xmaspattern.svg', center, center, repeat);
        background-size: 180px;
    }

    #nav-side {
        background-repeat: repeat-x;
        background-position: center bottom;
    }

    #logo-icon img {
        transform: scale(1.15);
    }

    &.templateAA #logo-icon img {
        transform: scale(1);
    }
}


//##############################################################################
//                                                                             #
//	! 1.2 GLOBAL -> HEADER                                                     #
//                                                                             #
//##############################################################################

#header {
    @include span-columns(12);
    position: relative;
    z-index: 80;
    background-color: $white;

    .posts-header-overlay {
        z-index: 998;
        background: rgb(83,187,236);
        background: radial-gradient(circle, rgba(83,187,236,1) 0%, rgba(70,159,201,1) 52%, rgba(59,136,172,1) 100%);
        opacity: 0.7;
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .posts-header-container {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;


        > div {
            display: flex;
            padding: 10px 20px 20px;
            height: 100%;
            width: 100%;

            justify-content: center;
            flex-direction: column;

            h1 > span {
                display: none;
            }
        }

        .posts-header-title {
            text-decoration: underline;
            text-underline-offset: 15px;
            position: relative;
            z-index: 999;
            background: transparent;
            color: $white;
            padding: 0;
            margin: 0;
            font-family: $altfontfamily;
            line-height: 1;
            font-weight: bold;
            font-size: em(30);
            margin-bottom: 15px;
            text-shadow: 4px 4px 8px rgba(0,0,0,0.16);;
        }
        .posts-header-subtitle {
            font-size: em(16);
            color: white;
            z-index: 999;
            text-shadow: 2px 2px 4px rgba(0,0,0,0.16);
        }

        @media only screen and (min-width: 640px) {
            .posts-header-title {
                font-size: em(70);
            }
            .posts-header-subtitle {
                font-size: em(20);
            }

            > div {

            }
        }

        @media only screen and (min-width: 900px) {
            .posts-header-title {
                font-size: em(100);
                text-decoration: none;
            }
            .posts-header-subtitle {
                font-size: em(20);
            }
            > div {
                justify-content: flex-start;
                flex-direction: row;
                align-items: center;

                h1 > span {
                    display: inline-block;
                }
            }
        }
    }



    .header-title {
        position: absolute;
        bottom: 15px;
        left: 0px;
        width: 100%;
        text-align: center;
        z-index: 999;
        background: transparent;
        color: $white;
        padding: 0;
        margin: 0;
        font-family: $altfontfamily;
        line-height: 1;
        font-weight: bold;
        font-size: em(36);

        .homepage & {
            bottom: auto;
            top: 30px;
        }
    }

    .header-tags {
        display: none;
    }

    .banner-image {
        position: relative;
        height: 200px;
        width: 100%;
        overflow: hidden;

        img {
            height: 200px;
            position: absolute;
            left: 50%;
            margin-left: -416px; //-416px;
            //@include transform(translateX(-50%));
        }
    }

    #logo-full {
        display: none;
    }

    &.carousel-artsaward {
        .owl-item {
            height: 200px;

            img {
                width: auto;
                height: 200px;
            }
        }
    }

    .owl-item {
        position: relative;
        overflow: hidden;
        text-align: center;
        width: 100%;
        height: 300px;

        img {
            position: absolute;
            top: 0px;
            left: 50%;
            margin-left: -416px;
            width: 833px;
            height: 300px;
        }

        .item-info {
            position: absolute;
            bottom: 30px;
            left: 5%;
            width: 90%;
            text-align: center;

            .item-info-link {
                color: $textcolor;
                display: inline-block;
                background: #fff;
                background: rgba(#fff, .7);

                &:hover {
                    h2 {
                        color: $primarycolor;
                    }

                    .fa {
                        margin-left: 8px;
                    }
                }
            }

            h2 {
                font-weight: bold;
                font-size: em(18);
                margin: 0;
                padding: 6px 20px 0;
                line-height: 1.2;
                transition: all 0.4s;
            }

            p {
                font-size: em(12);
                padding: 6px 20px;
                margin: 0;
                font-family: $baseheadingfont;
            }

            .item-cta {
                display: block;
                color: $primarycolor;
                font-size: em(15);
                padding: 0px 20px 10px;
            }

            .fa {
                transition: margin 0.4s;
            }
        }
    }

    .owl-prev,
    .owl-next {
        display: none;
        position: absolute;
        top: 0px;
        right: auto;
        bottom: 0px;
        left: 0px;
        width: 80px;

        .fa {
            color: #fff;
            padding: 0 2px;
            font-size: em(40);
            line-height: 1;
            position: absolute;
            top: 50%;
            margin-top: -20px;
            left: 20px;
            transition: all 0.2s;
        }

        &:hover {
            .fa {
                color: $primarycolor;
            }
        }
    }

    .owl-next {
        left: auto;
        right: 0px;

        .fa {
            left: auto;
            right: 20px;
        }
    }

    .owl-dots {
        position: absolute;
        bottom: 4px;
        width: 100%;
        text-align: center;

        .owl-dot {
            display: inline-block;
            background: #fff;
            opacity: 0.4;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            margin: 0 5px;

            &:hover {
                opacity: 1;
            }

            &.active {
                background: $primarycolor;
                opacity: 1;
            }
        }
    }
}

.homepage #header {
    //padding-bottom: 15px;
    @include bg('main/optimized/bg-gradientdrop.webp', left, bottom, repeat-x);

    .header-text {
        bottom: 25px;
    }
}


//##############################################################################
//                                                                             #
//	! 1.3 GLOBAL -> FOOTER                                                     #
//                                                                             #
//##############################################################################

#footer {
    @include span-columns(12);
    background: $white;

    .footer-links,
    .copyright {
        @include span-columns(12);
        border-top: $border;
    }

    .footer-links-inner {
        padding: 24px 40px 0;
        padding-bottom: 0px;
    }
}

.footer-sitemap {
    h2 {
        color: $primarycolor;
        font-size: em(18);
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 0 6px;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        &:last-child {
            margin-bottom: 20px;
        }
    }

    a {
        display: inline-block;
        padding: 7px 0;
        color: $lightneutralcolor;
        font-size: em(15);

        &:hover,
        &:focus {
            color: $primarycolor;
        }
    }

    i {
        width: 30px;
        text-align: left;
        font-size: em(24);
        vertical-align: middle;
    }

    .fb i {
        color: $fb;
    }

    .ig i {
        color: $ig;
    }

    .tw i {
        color: $tw;
    }

    .yt i {
        color: $yt;
    }
}

.footer-support-links {
    &, li{
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li{
        display: block;
    }

    a {
        display: inline-block;
        background: $primarycolor;
        color: $white;
        border-radius: 10em;
        padding: 7px 7px 7px 21px;
        margin-bottom: 20px;

        .fa {
            font-size: 1em;
            margin-left: 7px;
            margin-right: 14px;
            width: auto;
        }
    }
}

.footer-logo {
    padding: 40px;
    padding-top: 0;
    text-align: center;

    img {
        width: 176px;
    }
}

.copyright {
    .from,
    .with {
        padding: 20px;
    }

    .with {
        padding-top: 0px;
    }

    img {
        height: 40px;
    }

    .img2 {
        display: block;
        margin-top: 20px;
    }

    .from {
        img {
            height: 50px;
        }
    }

    p {
        margin: 10px 0 0 0;
    }
}


//##############################################################################
//                                                                             #
//	! 2.1 NAV -> MAIN                                                          #
//                                                                             #
//##############################################################################

#nav-bar {
    @include span-columns(12);
    position: relative;
    z-index: 99;
    background: $white;
    box-shadow: 0 5px 5px rgba($black, 0.25);
}

#logo-icon {
    float: left;
    padding: 10px 20px;
    padding-right: 0;
    align-items: center;

    img {
        height: 40px;
    }
}

#logo-slogan {
    font-size:14px;
    margin-left:7px;
    display:flex;
    flex-direction:column;
    font-family: $altfontfamily;
    line-height: 1;
    font-weight: 600;

    span span {
        color: #D64B8B;
    }
}

@media only screen and (min-width: 375px) {
    #logo-slogan {
        margin-left:15px;
    }
}

@media only screen and (max-width: 340px) {
    #logo-slogan {
        display: none;
    }
}


@media only screen and (min-width: 640px) {
    #logo-slogan {
        font-size:20px;
        margin-left:30px;
    }
}

.templateAA {
    #logo-icon {
        padding: 10px 20px;

        img {
            height: 40px;
        }
    }
}

.nav-toggle {
    float: right;

    a {
        display: block;
        font-size: em(30);
        line-height: 1;
        //background: #eee;
        padding: 15px 20px;
        padding-right: 20px;
    }
}

#nav,
.nav-funcs {
    display: none;
}

.nav-wrapper {
    float: right;
}

#nav {
    float: left;
}

.nav-primary {
    float: left;
    margin: 0;
    padding: 0;
    //border-left: $border;

    ul {
        display: none;
    }

    .home,
    .only-sb {
        display: none;
    }
}

.templateAA {
    .nav-toggle a {
        color: $aa-primarycolor;
    }

    .nav-links {
        li {
            &:hover,
            &:focus {
                a {
                    color: $aa-primarycolor;
                }

                ul {
                    a {
                        color: $neutralcolor;
                    }
                }
            }
        }

        a {
            &:hover,
            &:focus {
                color: $aa-primarycolor;
            }
        }

        .dropdown-menu {
            a {
                &:hover,
                &:focus {
                    background: $aa-primarycolor;
                    color: $white;
                }
            }
        }

        .dropdown-menu .dropdown-submenu {
            &:hover,
            &:focus {
                a {
                    background: $aa-primarycolor;
                    color: $white;
                }

                ul {
                    a {
                        background: $white;
                        color: $neutralcolor;

                        &:hover,
                        &:focus {
                            background: $aa-primarycolor;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .nav-funcs {
        .form-login {
            .forgot {
                color: $primarycolor;
            }

            .register {
                color: $white;
            }

            .row-social-logins {
                span {
                    color: $lighterneutralcolor;
                }

                a {
                    color: $lighterneutralcolor;
                }
            }
        }
    }
}

.templateTR {
    .case-studies-intro {
        h3 {
            color: $primarycolor;
        }

        a {
            text-decoration: underline;
        }

        padding: 0px 20px 0px;
    }

    .nav-toggle a {
        color: $tr-primarycolor;
    }

    .nav-links {
        li {
            &:hover,
            &:focus {
                a {
                    color: $tr-primarycolor;
                }

                ul {
                    a {
                        color: $neutralcolor;
                    }
                }
            }
        }

        a {
            &:hover,
            &:focus {
                color: $tr-primarycolor;
            }
        }

        .dropdown-menu {
            a {
                &:hover,
                &:focus {
                    background: $tr-primarycolor;
                    color: $white;
                }
            }
        }

        .dropdown-menu .dropdown-submenu {
            &:hover,
            &:focus {
                a {
                    background: $tr-primarycolor;
                    color: $white;
                }

                ul {
                    a {
                        background: $white;
                        color: $neutralcolor;

                        &:hover,
                        &:focus {
                            background: $tr-primarycolor;
                            color: $white;
                        }
                    }
                }
            }
        }
    }

    .nav-funcs {
        .form-login {
            .forgot {
                color: $primarycolor;
            }

            .register {
                color: $white;
            }

            .row-social-logins {
                span {
                    color: $lighterneutralcolor;
                }

                a {
                    color: $lighterneutralcolor;
                }
            }
        }
    }
}

.nav-links {
    li {
        float: left;
        //border-right: $border;
        position: relative;

        &:hover,
        &:focus {
            a {
                color: $primarycolor;
            }

            ul {
                display: block;

                a {
                    color: $neutralcolor;
                }
            }
        }
    }

    a {
        float: left;
        color: $neutralcolor;
        font-family: $baseheadingfont;
        font-weight: bold;
        font-size: em(16);
        transition: all 0.8s;
        padding: 0 18px;
        height: 60px;
        line-height: 60px;

        &:hover,
        &:focus {
            color: $primarycolor;
        }
    }

    .icon {
        a {
            padding: 10px 20px;
            height: 40px;

            img {
                display: block;
                height: 40px;
            }
        }
    }

    .dropdown-menu {
        margin: 0;
        padding: 15px 0;
        position: absolute;
        top: 100%;
        left: -1px;
        width: 278px;
        background-color: $white;
        border: $border;
        border-top: none;
        //box-shadow: inset 0px 5px 5px rgba($black, 0.25);
        @include bg('main/optimized/bg-gradientdrop.webp', left, -15px, repeat-x);

        li {
            float: none;
            display: block;
            border: none;
        }

        a {
            float: none;
            display: block;
            padding: 15px 20px;
            height: auto;
            text-transform: none;
            font-weight: normal;
            font-size: em(15);
            line-height: 1.2;
            transition: all 0.4s;

            &:hover,
            &:focus {
                background: $primarycolor;
                color: $white;
            }
        }
    }

    .dropdown-menu .dropdown-submenu {
        ul {
            display: none;
            top: 0; //-21px;
            left: 100%;
            background-image: none;
            border: $border;
            //padding: 0px;
        }

        &:hover,
        &:focus {
            a {
                background: $primarycolor;
                color: $white;
            }

            ul {
                display: block;

                a {
                    background: $white;
                    color: $neutralcolor;

                    &:hover,
                    &:focus {
                        background: $primarycolor;
                        color: $white;
                    }
                }

                .dropdown-menu {
                    display: none;
                }
            }
        }

        a {
            position: relative;
        }

        i {
            position: absolute;
            top: 50%;
            margin-top: -8px;
            right: 20px;
            font-size: 1em;
        }

        .dropdown-menu {
            i {
                display: none;
            }
        }
    }
}

.nav-funcs {
    float: right;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        float: left;
        //border-right: $border;
        position: relative;

        &:hover {
            a {
                color: $primarycolor;
            }

            .dropdown-cont {
                display: block;
            }
        }
    }

    .divider {
        span {
            display: block;
            width: 2px;
            background: $lightestneutralcolor;
            height: 40px;
            margin: 0 10px;
            margin-top: 10px;
        }
    }

    a {
        span {
            display: none;
        }
    }

    .artsaward {
        a {
            color: $aa-primarycolor;

            &:hover {
                color: $aa-primarycolor;

                .text-logo {
                    .text-logo--logo {
                        opacity: 1;
                    }

                    .text-logo--text {
                        opacity: 0;
                    }
                }
            }

            .text-logo {
                display: inline-block;
                position: relative;

                .text-logo--logo {
                    position: absolute;
                    width: 30px;
                    top: 14px;
                    left: 50%;
                    transform: translateX(-50%);
                    opacity: 0;
                    transition: .5s;
                }

                .text-logo--text {
                    display: inline-block;
                    transition: .5s;
                }
            }
        }

        .fa {
            position: relative;
            top: 1px;
            margin-left: 4px;
            font-size: 22px;
        }
    }

    .dropdown-cont {
        display: none;
        position: absolute;
        top: 100%;
        right: 0px;
        padding: 20px;
        background: $white;
        border: $border;
        border-top: none;
        @include bg('main/optimized/bg-gradientdrop.webp', left, -15px, repeat-x);

        a {
            line-height: 1.2;
        }
    }

    .newsletter .dropdown-cont {
        right: auto;
        left: 0px;
    }

    .form {
        .row {
            position: relative;
            //padding-left: 160px;
            margin-bottom: 8px;
        }

        label {
            position: absolute;
            top: 3px;
            left: 0px;
        }

        .row-checkbox label {
            font-weight: bold;
        }

        .help-block {
            font-size: 0.7em;

            a {
                float: none;
                padding: 0;
                height: auto;
                font-weight: normal;
                line-height: inherit;
            }
        }

        .row-submit {
            margin: 0;

            button {
                width: 278px;
                margin: 0;
                background: $primarycolor;
                transition: all 0.4s;

                &:hover {
                    background: darken($primarycolor, 15%);
                }
            }
        }
    }

    .form-login {
        .row {
            padding-left: 160px;
        }

        a {
            float: none;
            padding: 0;
            height: auto;
            text-transform: none;
            font-weight: normal;
        }

        .forgot {
            position: absolute;
            top: 9px;
            left: 0px;
            font-size: em(12);
            font-family: $baseheadingfont;
            color: $primarycolor;
        }

        .register {
            display: block;
            margin-top: 30px;
            padding: 6px 10px;
            text-align: center;
            line-height: 1.3;
            font-size: em(15);
            font-family: $baseheadingfont;
            color: $white;
            background: $secondarycolor;
            text-transform: uppercase;
            font-weight: bold;

            &:hover {
                background: lighten($secondarycolor, 15%);
                color: $white;
            }
        }

        .row-social-logins {
            padding-left: 0;
            margin-top: 30px;
            text-align: center;

            span {
                display: block;
                margin-bottom: 4px;
                color: $lighterneutralcolor;
                text-transform: uppercase;
                font-family: $baseheadingfont;
                font-weight: bold;
                font-size: em(13);
            }

            a {
                display: inline-block;
                margin: 0 15px;
                padding: 6px;
                color: $lighterneutralcolor;

                i {
                    margin-right: 6px;
                }
            }

            .fb {
                color: $fb;
            }

            .gg {
                color: $gg;
            }

            .tw {
                color: $tw;
            }
        }
    }

    .loggedin-info {

        a {
            float: none;
            padding: 0;
            height: auto;
        }

        .user-info {
            .image-profile {
                display: table-cell;
                vertical-align: top;

                img {
                    width: 70px;
                    height: 70px;
                    background: $primarycolor;
                }
            }

            p {
                white-space: nowrap;
                display: table-cell;
                vertical-align: middle;
                padding-left: 10px;
                margin: 0;
                font-family: $baseheadingfont;
                color: $neutralcolor;
                line-height: 1;

                br {
                    display: none;
                }

                i {
                    font-size: em(14);
                    font-style: normal;
                    color: $lighterneutralcolor;
                    text-transform: uppercase;
                    font-weight: bold;
                }

                b {
                    display: block;
                    font-size: em(22);
                    font-weight: bold;
                    padding: 4px 0;
                }

                a {
                    color: $primarycolor;
                    font-size: em(14);
                }
            }
        }

        .user-links {
            margin-top: 20px;
            white-space: nowrap;

            li {
                border: none;
                float: none;
                display: inline-block;

                &:first-child {
                    a {
                        margin: 0;
                    }
                }
            }

            a {
                //background: yellow;
                display: inline-block;
                text-align: center;
                padding: 6px 0 10px;
                padding-bottom: 0;
                margin-left: 6px;
                //color: $lighterneutralcolor;
                text-transform: uppercase;

                &:hover {
                    color: $primarycolor;
                }
            }

            .fa {
                font-size: em(32);
            }

            span {
                display: block;
                font-size: em(12);
                padding: 6px 8px 0;
            }
        }
    }
}

.loggedin-info {
    .user-links {
        @extend .clearfix;
    }
}


//##############################################################################
//                                                                             #
//	! 2.2 NAV -> SUB                                                           #
//                                                                             #
//##############################################################################

#nav-sub,
.nav-sub {
    display: none;
    float: left;
    width: 100%;
    position: relative;
    background: $primarycolor;

    .templateAA & {
        background: $aa-primarycolor;
    }

    .templateTR & {
        background: $tr-primarycolor;
    }

    &.nav-sub-lower {
        display: block;
    }

    ul {
        list-style: none;
        margin: 8px 0 0;
        padding: 0 8px;
        text-align: center;
    }

    .nav-sub-artsaward-homepage {
        margin-bottom: 8px;
    }

    .divider {
        display: inline;

        &:before {
            content: "\A";
            white-space: pre;
        }
    }

    li {
        display: inline-block;
        margin-bottom: 8px;
    }

    a {
        display: inline-block;
        color: #fff;
        font-family: $baseheadingfont;
        font-size: em(17);
        font-weight: bold;
        padding: 5px 10px;
        border-radius: 4px;
        text-shadow: 1px 1px 1px darken($primarycolor, 5%);
        transition: all 0.6s;

        .templateAA & {
            text-shadow: 1px 1px 1px darken($aa-primarycolor, 5%);
        }

        .templateTR & {
            text-shadow: 1px 1px 1px darken($tr-primarycolor, 5%);
        }

        &:hover {
            //text-decoration: underline;
            background: lighten($primarycolor, 10%);
            text-shadow: 1px 1px 1px darken($primarycolor, 15%);

            .templateAA & {
                background: lighten($aa-primarycolor, 10%);
                text-shadow: 1px 1px 1px darken($aa-primarycolor, 15%);
            }

            .templateTR & {
                background: lighten($tr-primarycolor, 10%);
                text-shadow: 1px 1px 1px darken($tr-primarycolor, 15%);
            }
        }
    }

    &.climatecrisis {
        $color: #23b25c;
        background: $color;

        a {
            text-shadow: 1px 1px 1px darken($color, 5%);
            color: #fff;

            &:hover {
                background: darken($color, 10%);
                text-shadow: 1px 1px 1px darken($color, 15%);
            }
        }
    }
}


//##############################################################################
//                                                                             #
//	! 2.3 NAV -> SLIDEBAR                                                      #
//                                                                             #
//##############################################################################

.templateAA {
    #nav-side {
        background: $aa-primarycolor;
    }

    .nav-divider {
        border-color: lighten($aa-primarycolor, 20%);
    }

    .nav-side-main {
        .open {
            a,
            .open a {
                background: lighten($aa-primarycolor, 20%);
            }

            ul {
                a {
                    background: none;
                }
            }

            .open {
                ul {
                    a {
                        background: none;
                    }
                }
            }
        }

        .divider {
            &:before {
                border-color: lighten($aa-primarycolor, 20%);
            }
        }
    }

    .form.sb-cont {
        .row-submit {
            button {
                background: darken($aa-primarycolor, 10%);

                &:hover {
                    background: darken($aa-primarycolor, 15%);
                }
            }
        }

        .register {
            background: $aa-secondarycolor;

            &:hover {
                background: lighten($aa-secondarycolor, 15%);
            }
        }
    }
}

.templateTR {
    #nav-side {
        background: $tr-primarycolor;
    }

    .nav-divider {
        border-color: lighten($tr-primarycolor, 20%);
    }

    .nav-side-main {
        .open {
            a,
            .open a {
                background: lighten($tr-primarycolor, 20%);
            }

            ul {
                a {
                    background: none;
                }
            }

            .open {
                ul {
                    a {
                        background: none;
                    }
                }
            }
        }

        .divider {
            &:before {
                border-color: lighten($tr-primarycolor, 20%);
            }
        }
    }

    .form.sb-cont {
        .row-submit {
            button {
                background: darken($tr-primarycolor, 10%);

                &:hover {
                    background: darken($tr-primarycolor, 15%);
                }
            }
        }

        .register {
            background: $tr-secondarycolor;

            &:hover {
                background: lighten($tr-secondarycolor, 15%);
            }
        }
    }
}

#nav-side {
    background: $primarycolor;

    .nav-sidebar {
        padding-bottom: 40px;
    }

    a {
        color: $white;
    }

    ul,
    li {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: inline;
    }

    .nav-side-top {
        margin-bottom: 20px;
    }

    .nav-divider {
        margin: 20px 20px 0;
        border-top: 2px solid lighten($primarycolor, 20%);
        padding: 20px 0 0 0;

        .templateAA & {
            border-color: lighten($aa-primarycolor, 20%);
        }
    }
}

.nav-side-top {
    @extend .clearfix;

    a {
        position: relative;
        float: left;
        width: 50%;
        text-align: center;
        border-bottom: 1px solid $white;
    }

    i {
        font-size: em(30);
        padding: 15px;
    }

    .fa-close {
        display: none;
    }

    .active {
        .fa {
            display: none;
        }

        .fa-close {
            display: block;
        }
    }

    .account a:before {
        content: "";
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 0px;
        width: 1px;
        background: $white;
    }
}

.nav-side-main {
    a {
        position: relative;
        display: block;
        font-family: $baseheadingfont;
        font-size: em(18);
        padding: 10px 20px;
    }

    i {
        position: absolute;
        right: 0px;
        top: 0px;
        font-size: em(26, 18);
        line-height: em(47, 26);
        padding: 0px 20px;
    }

    ul {
        display: none;

        a {
            padding-left: 40px;
            font-size: em(15);
        }

        ul a {
            padding-left: 60px;
        }
    }

    .open {
        a,
        .open a {
            background: lighten($primarycolor, 20%);
        }

        i {
            font-size: em(18, 18);
            line-height: em(47, 18);
        }

        ul {
            display: block;

            a {
                background: none;
            }

            i {
                font-size: em(26, 15);
                line-height: em(42, 26);
            }
        }

        li {
            ul {
                display: none;
            }
        }

        .open {
            ul {
                display: block;

                a {
                    background: none;
                }
            }

            i {
                font-size: em(18, 15);
                line-height: em(42, 18);
            }

            .dropdown-menu {
                ul {
                    display: none;
                }

                i {
                    display: none;
                }
            }
        }
    }

    .divider {
        &:before {
            content: "";
            display: block;
            margin: 20px 20px 0;
            border-top: 2px solid lighten($primarycolor, 20%);
            padding-top: 20px;
        }
    }
}

.sb-cont {
    display: none;
    padding: 0 20px;
}

.sb-nav {
    display: block;
}

.form.sb-cont {
    .row {
        margin-bottom: 20px;
    }

    label {
        display: block;
        color: $white;
    }

    input,
    select {
        border: none;
        background-color: $white;
    }

    .row-submit {
        button {
            display: block;
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            background: darken($primarycolor, 10%);
        }
    }

    .forgot {
        display: block;
        margin-top: 15px;
        font-size: em(13);
    }

    .row-social-logins {
        @extend .clearfix;
        text-align: center;

        span {
            display: block;
            text-transform: uppercase;
            color: $white;
            font-family: $baseheadingfont;
            font-size: em(14);
            font-weight: bold;
            margin-bottom: 10px;
        }

        a {
            display: block;
            float: left;
            width: 33%;

            font-size: em(30);
        }
    }

    .register {
        display: block;
        margin-top: 30px;
        background: $secondarycolor;
        color: $white;
        padding: 6px 10px;
        text-align: center;
        line-height: 1.3;
        font-size: em(15);
        font-family: $baseheadingfont;
        text-transform: uppercase;
        font-weight: bold;

        &:hover {
            background: lighten($secondarycolor, 15%);
            color: $white;
        }
    }
}

.sb-cont.loggedin-info {
    a {
        float: none;
        padding: 0;
        height: auto;
    }

    .user-info {
        margin-bottom: 10px;

        .image-profile {
            display: table-cell;
            vertical-align: top;

            img {
                width: 70px;
                height: 70px;
                background: #fff;
            }
        }

        p {
            white-space: nowrap;
            display: table-cell;
            vertical-align: middle;
            padding-left: 10px;
            margin: 0;
            font-family: $baseheadingfont;
            color: $white;
            line-height: 1;

            br {
                display: none;
            }

            i {
                font-size: em(14);
                font-style: normal;
                color: $white;
                text-transform: uppercase;
                font-weight: bold;
            }

            b {
                display: block;
                font-size: em(22);
                font-weight: bold;
                padding: 4px 0;
            }

            a {
                font-size: em(14);
            }
        }
    }

    .user-links {
        a {
            //background: yellow;
            display: block;
            padding: 6px;
            margin-top: 10px;
            text-align: left;
        }

        .fa {
            font-size: em(22);
            width: 22px;
            text-align: center;
            margin-right: 10px;
            vertical-align: middle;
        }

        span {
            font-size: 1em;
            vertical-align: middle;
            display: inline;
            padding: 0;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 3 CONTENT -> ASIDE                                                       #
//                                                                             #
//##############################################################################

.block-aside {
    @include span-columns(12);
    border-top: $border;

    h3 {
        font-family: $altfontfamily;
        font-size: em(30);
        line-height: 1;
        color: $secondarycolor;
        margin: 20px;
    }

    &.aside-alt {
        h3 {
            color: $primarycolor;
        }
    }

    p {
        margin: 0 20px 20px;
        color: $neutralcolor;
        font-size: em(15);
    }

    .intro {
        margin-top: -15px;
    }

    .placeholder {
        font-size: em(13);
    }
}

.explore-sections {
    &.explore--noheading {
        padding-top: 20px;
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0 0 20px 0;

        ul {
            margin: 0;
        }
    }

    .js & {
        .dropdown__openclose {
            ul {
                display: none;
            }

            .active {
                ul {
                    display: block;
                }

                .dropdown__open {
                    display: none;
                }

                .dropdown__close {
                    display: block;
                }
            }

            .hasChildren__a {
                margin-right: 60px;
                position: relative;
            }

            .dropdown__open {
                display: block;
            }
        }
    }

    li {
        display: inline;
    }

    a {
        display: block;
        background: $secondarycolor;
        color: $white;
        font-size: em(18);
        font-family: $baseheadingfont;
        margin: 0 20px 10px 20px;
        padding: 10px 20px;

        .fa {
            margin-right: 10px;
        }

        &:hover {
            background: lighten($secondarycolor, 10%);
        }

        .dropdown__btn {
            display: none;
            position: absolute;
            top: 0px;
            right: -40px;
            bottom: 0px;
            width: 40px;
            padding: 14px 0 0;
            margin: 0;
            text-align: center;
            background: $secondarycolor;
            border-left: 1px solid $white;
        }
    }

    .alt,
    &.aside-alt {
        a {
            background: $primarycolor;

            &:hover {
                background: darken($primarycolor, 10%);
            }
        }
    }

    .current {
        a {
            margin-left: 0;
            padding-left: 40px;
            background: $secondarycolor;
        }
    }

    .dropdown-menu {
        a {
            padding-left: 50px;
        }
    }

    @each $name, $color in $aa-list {
        .colour-#{""+$name} {
            a,
            .dropdown__btn {
                background: $color;

                &:hover {
                    background: darken($color, 10%);
                }
            }
        }
    }

    .colour-tw a,
    .colour-twitter a {
        background: $tw;

        &:hover {
            background: darken($tw, 10%);
        }
    }

    .colour-fb a,
    .colour-facebook a {
        background: $fb;

        &:hover {
            background: darken($fb, 10%);
        }
    }

    .colour-gg a,
    .colour-google a {
        background: $gg;

        &:hover {
            background: darken($gg, 10%);
        }
    }
}

.explore-picks {
    h3 {
        color: $primarycolor;
    }
}

.list-thumb-simple {
    h4 {
        display: inline-block;
        margin: 6px 0 0 0;
        font-size: em(16);
        font-weight: normal;
        color: $white;
        background: $secondarycolor;
        padding: 0px 10px 0px 20px;
    }

    p {
        margin: 10px 0 0 20px;
        color: $lightneutralcolor;
        font-size: em(13);
    }
}

.list-thumb-content {
    list-style: none;
    margin: 0 20px;
    padding: 0;

    li {
        margin: 0 0 20px 0;

        a {
            display: block;
            min-height: 120px;
            padding-left: 120px;
            position: relative;

            &:hover {
                img {
                    opacity: 1;
                }
            }
        }

        img {
            width: 120px;
            opacity: 0.9;
        }
    }

    .post-img {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 120px;
    }

    .post-type {
        @extend .type-ribbon;
    }

    h4 {
        font-size: em(16);
        line-height: 1.2;
        color: $textcolor;
        font-weight: bold;
        margin: 7px 0 0 20px;
    }

    .post-author,
    .post-date {
        display: block;
        font-size: em(13);
        color: $lightneutralcolor;
        margin: 0 0 0 20px;
    }

    .post-author {
        margin-bottom: 7px;
        margin-top: 2px;
    }

    .post-author-level,
    .post-template-icon {
        position: absolute;
        right: 5px;

        img {
            display: block;
            width: auto;
            max-width: none;
            opacity: 1;
        }
    }

    .post-author-level {
        bottom: 5px;

        img {
            height: 20px;
        }
    }

    .post-template-icon {
        top: 5px;

        img {
            height: 18px;
        }
    }

    &.no-section {
        h4 {
            @extend .type-ribbon;
            display: inline-block;
            margin-left: 0px;
            text-transform: none;
            font-size: em(16);
            font-weight: normal;
        }
    }
}

.list-post-comment {
    list-style: none;
    margin: 0 20px;
    padding: 0;

    li {
        margin: 30px 0 0 0;

        &:first-child {
            margin: 0;
        }
    }

    .post-info {
        display: table;

        .post-img,
        .post-title {
            display: table-cell;
        }

        .post-img {
            vertical-align: top;
            width: 48px;

            img {
                width: 100%;
            }
        }

        .post-title {
            padding-left: 10px;
            vertical-align: middle;
            font-size: em(12);
            color: $neutralcolor;

            b {
                display: block;
                font-family: $baseheadingfont;
                color: $textcolor;
                font-size: em(14, 12);
            }
        }
    }

    .comment-meta,
    .comment-text {
        font-size: em(14);
        color: $neutralcolor;
        margin: 10px 0 0 0;
    }

    .comment-text {
        margin: 0;
        color: $darkneutralcolor;

        p {
            margin: 10px 0 0 0;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 3 CONTENT -> BODY                                                        #
//                                                                             #
//##############################################################################

.post-wrap {
    padding: 20px;
    padding-top: 10px;

    .post-title {
        h1 {
            font-weight: bold;
            font-size: em(34);
            margin: 0;
        }

        .post-author {
            font-size: em(18);
            font-family: $baseheadingfont;
            color: $lightneutralcolor;
            margin: 0;

            a {
                color: $lightneutralcolor;
            }
        }
    }

    .post-summary {
        p {
            font-size: em(22);
            line-height: 1.4;
            font-family: $baseheadingfont;
            margin: 20px 0 0 0;
        }
    }

    .post-img {
        margin: 20px 0 0 0;

        img {
            max-width: 100%;
        }
    }

    .event-info {
        border-bottom: 1px solid $lightestneutralcolor;
        padding-bottom: 4px;
    }

    .post-content-deprec {
        p {
            margin: 20px 0 0 0;

            &:first-child {
                margin-top: 20px;
            }
        }

        li {
            margin: 8px 0 0 0;
        }

        img {
            max-width: 100%;
        }

        hr {
            border: 0;
            height: 1px;
            background: $lightestneutralcolor;
        }
    }

    .post-quote {
        display: block;
        padding: 30px 40px 0 60px;
        @include bg('main/icon-blockquote.png', 15px, 30px);

        blockquote {
            font-family: $baseheadingfont;
            font-style: italic;
            font-size: em(24);
            margin: 0;
        }

        p:first-child {
            margin-top: 0;
        }
    }

    .post-footer {
        margin-top: 40px;
        width: 100%;

        ul {
            vertical-align: middle;
            list-style: none;
            margin: 0;
            padding: 0;

            &.post-cta {
                margin-top: 20px;
            }

            li {
                font-family: $altfontfamily;
                font-size: em(24);
                color: $midneutralcolor;

                b {
                    color: $lightneutralcolor;
                    font-weight: normal;
                }
            }
        }
    }

    .post-notice {
        background: $primarycolor;
        padding: 15px 20px;
        margin-bottom: 20px;

        .templateAA & {
            background: $aa-primarycolor;
        }

        img {
            width: 16px;
        }

        p {
            margin: 10px 0 0 0;
            color: $white;
            font-size: em(12);
        }

        a {
            color: $white;
            font-weight: bold;
            text-decoration: underline;
        }
    }

    .post-cta {
        min-width: 240px;

        li {
            margin: 8px 0 0 0;

            &:first-child {
                margin-top: 0;
            }
        }

        button {
            background: none;
            border: none;
            font-family: inherit;
        }

        a,
        button,
        .fake-btn {
            display: inline-block;
            color: $secondarycolor;
            clear: both;
            white-space: nowrap;

            .fa {
                float: left;
                font-size: em(30, 24);
                margin-right: 20px;
                vertical-align: middle;
            }
        }

        .fake-btn {
            color: $primarycolor;
        }
    }

    .post-rating {
        text-align: center;
        margin: 40px 0;
        font-size: em(40);

        .fa-star {
            color: $primarycolor;
        }

        .fa-star-o {
            color: $lighterneutralcolor;
        }
    }

    .post-imagecredit {
        margin-top: 40px;
        font-style: italic;
        color: $lightneutralcolor;
    }
}

.post-warning {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $primarycolor;
    color: $white;
    padding: 1.4em 2em;
    margin-top: 1em;

    .warning-msg {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        font-size: 1.1em;

        p{
            margin: 0;
            padding-left: 1em;
        }
    }

    .button {
        background: $white;
        color: $primarycolor;
        text-transform: uppercase;
        font-weight: bold;
        border: 0;
        padding: .6em 1em;
        font-size: .9em;
    }
}

.post-content,
.page-content,
.post-summary {
    clear: both;

    p {
        margin: 20px 0 0 0;

        &:first-child {
            margin-top: 20px;
        }
    }

    ul,
    ol {
        margin: 20px 0 0 0;
    }

    li {
        margin: 8px 0 0 0;
    }

    .generatedcontent & {
        ul {
            list-style: none;
            padding: 0;

        }

        li {
            position: relative;
            padding-left: 46px;

            &:before {
                position: absolute;
                top: 2px;
                left: 20px;
                content: "•";
                padding-right: 10px;
                font-size: 20px;
                line-height: 1;
                font-weight: bold;
                color: $primarycolor;
            }
        }

        ol {
            padding-left: 40px;

            li {
                padding: 0 0 0 6px;

                &:before {
                    content: none;
                }
            }
        }
    }

    img {
        max-width: 100%;
    }

    hr {
        border: 0;
        height: 1px;
        background: $lightestneutralcolor;
        clear: both;
    }

    object,
    embed,
    iframe {
        margin: 20px 0 0 0;
        //width: 100% !important;
        //height: auto !important;
        max-width: 100%;
    }

    .twitter-tweet {
        margin: 20px 0 !important;
    }

    .js & {
        .fluid-width-video-wrapper {
            margin: 20px 0 0 0;

            object,
            embed,
            iframe {
                margin: 0;
            }
        }
    }

    blockquote {
        display: block;
        padding: 30px 40px 10px 60px;
        @include bg('main/icon-blockquote.png', 15px, 30px);
        font-family: $baseheadingfont;
        font-style: italic;
        font-size: em(24);
        margin: 0;

        p:first-child {
            margin-top: 0;
        }

        blockquote {
            font-size: 1em;
        }
    }

    table {
        margin: 25px 0;
        border-collapse: collapse;
        border: 1px solid $lightestneutralcolor;
        border-bottom: 2px solid $lighterneutralcolor;

        tr {
            &:hover {
                background: lighten($lightestneutralcolor, 10%);

                td {
                    color: #000;
                }
            }
        }

        th, td {
            color: #666;
            border: 1px solid $lightestneutralcolor;
            padding: 14px 20px;
            border-collapse: collapse;

            p,
            p:first-child {
                margin: 0;
            }
        }

        th {
            background: $primarycolor;
            color: #fff;
            text-transform: uppercase;
            font-size: em(14);
        }
    }
}

.page-wrap {
    @extend .post-wrap;
    @extend .clearfix;

    .page-title {
        @extend .post-title;
        margin-bottom: 20px;
    }

    .page-meta {
        @extend .clearfix;
        margin: 0;

        b, span {
            float: left;
            margin-bottom: 4px;
            font-family: $baseheadingfont;
            font-size: em(18);
            color: $lightneutralcolor;
        }

        b {
            width: 80px;
            clear: both;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 3 CONTENT -> HUB                                                         #
//                                                                             #
//##############################################################################

.page-wrap {
    .hub-title {
        margin: -10px -20px 30px;
        padding: 10px 20px 0px;
        background-position: right top;
        background-repeat: no-repeat;

        h1 {
            color: #fff;
            margin-bottom: 5px;
        }

        .post-summary {
            p {
                color: #fff;
                margin: 0;
                padding: 0 0 20px 0;
            }
        }
    }

    .hub-title--headingonly {
        padding-bottom: 10px;
    }
}

.hub-intro {
    position: relative;
    z-index: 21;
    background: $white;
    border-top: $border;
    padding: 20px;

    .hub-intro-links {
        a {
            display: block;
            padding: 20px 0;
            margin-bottom: 20px;
            text-align: center;
            font-size: em(18);
            line-height: 1;
            font-family: $baseheadingfont;
            font-weight: bold;
            color: $white;
            background: tint($aa-primarycolor, 40%);
            text-shadow: 1px 1px 4px $aa-primarycolor;
            transition: .5s;

            .fa,
            &.current:hover .fa {
                margin-left: 8px;
                transition: .5s;
            }

            &.current,
            &:hover {
                background: $aa-primarycolor;
                text-shadow: none;
            }

            &:hover {
                .fa {
                    margin-left: 16px;
                }
            }
        }
    }

    .hub-intro-summary {
        p {
            font-size: em(14);
            color: $neutralcolor;
            margin: 0 0 1em;

            &:last-child {
                margin: 0;
            }
        }
    }
}

#hubTabs {
    margin-top: 10px;
}

.menu__bubbles,
.r-tabs-accordion-title {
    list-style: none;
    margin: 0 0 30px 0;
    padding: 0;

    li {
        margin-bottom: 15px;
    }

    a {
        display: table;
        width: 100%;
    }

    .bubble__bg,
    .bubble__text {
        display: table-cell;
        vertical-align: middle;
    }

    .bubble__bg {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        box-shadow: 1px 1px 6px 1px rgba($black, .3);

        .bg__wrap {
            display: block;
            border: 2px solid $white;
            border-radius: 50%;
            transition: all 0.4s;
        }

        img {
            width: 100%;
            border-radius: 50%;
            opacity: 0.6;
        }
    }

    .bubble__text {
        padding-left: 15px;
        padding-right: 30px;
        position: relative;

        .bubble__title,
        .bubble__subtitle {
            display: block;
            font-weight: normal;
            font-style: normal;
            text-transform: uppercase;
        }

        .bubble__title {
            font-family: $altfontfamily;
            font-size: 24px;
            color: $black;
            line-height: 1;
        }

        .bubble__subtitle {
            margin-top: 3px;
            color: $neutralcolor;
            font-size: 11px;
            line-height: 1;
        }

        .fa {
            display: none;
            position: absolute;
            right: 0px;
            top: 50%;
            margin-top: -8px;
        }

        .state__closed {
            display: block;
        }
    }

    &.r-tabs-state-active {
        .bubble__text {
            .state__closed {
                display: none;
            }

            .state__open {
                display: block;
            }
        }
    }

    .bubbles__spacer {
        width: 60px;
        position: relative;
        display: none;

        .spacer__bubble {
            width: 22px;
            height: 22px;
            position: absolute;
            top: 61%;
            left: 2px;
            margin-top: -11px;
            border-radius: 50%;
        }

        .bubble__2 {
            width: 16px;
            height: 16px;
            top: 50%;
            left: 32px;
            margin-top: -8px;
        }

        .bubble__3 {
            width: 8px;
            height: 8px;
            top: 39%;
            left: 50px;
            margin-top: -4px;
        }

        &.bubbles__spacer--alt {
            .bubble__1 {
                top: 37%;
            }

            .bubble__3 {
                top: 63%;
            }
        }

        .spacer__placeholder {
            display: block;
            width: 60px;
        }
    }
}

.menu__bubbles .r-tabs-state-active,
.r-tabs-accordion-title.r-tabs-state-active {
    .bubble__bg {
        .bg__wrap {
            border-width: 4px;
        }
    }
}

.r-tabs-accordion-title {
    margin-bottom: 15px;
}

.section__tabbed {
    margin-bottom: 45px;
}

.r-tabs-panel {
    .tabs__title {
        display: none;
    }
}

@each $name, $color in $aa-list {
    .hub__#{""+$name} {
        .menu__bubbles .bubble__text .fa,
        .r-tabs-accordion-title .bubble__text .fa,
        .explore-picks h2 {
            color: $color;
        }

        .r-tabs-state-active .bubble__bg .bg__wrap {
            border-color: $color;
        }

        .hub-title,
        .bubbles__spacer .spacer__bubble {
            background-color: $color;
        }


        .hub-intro {
            .hub-intro-links {
                a {
                    background: tint($color, 40%);
                    text-shadow: 1px 1px 4px $color;

                    &.current,
                    &:hover {
                        background: $color;
                        text-shadow: none;
                    }
                }
            }
        }
    }
}


//##############################################################################
//                                                                             #
//	! 3 CONTENT -> INTRO                                                       #
//                                                                             #
//##############################################################################

.content-info-side {
    .block-inner {
        .hub__page & {
            background: none;
        }
    }

    h1 {
        font-family: $altfontfamily;
        font-size: em(30);
        line-height: 1;
        color: $primarycolor;
        margin: 0 0 20px 0;
    }

    .img {
        img {
            width: 100%;
        }
    }

    p {
        font-size: em(14);
        color: $midneutralcolor;
        margin: 20px 0 0 0;
    }
}

.content-info-full {
    h1 {
        font-family: $baseheadingfont;
        font-size: em(34);
        font-weight: bold;
        line-height: 1;
        margin: 0 0 10px 0;
    }

    h2 {
        font-size: em(16);
        font-weight: bold;
        margin: 20px 0 10px 0;
    }

    p {
        font-size: em(16);
        margin: 0 0 10px 0;
    }

    .intro {
        font-size: em(22);
        margin: 0;
    }

    .img {
        img {
            width: 100%;
            opacity: 0.2;
        }

        a {
            display: block;
            position: relative;

            b {
                display: block;
                position: absolute;
                top: 50%;
                left: 0px;
                right: 0px;
                margin-top: -51px;
                text-align: center;
                font-size: em(15);
                text-transform: uppercase;

                .fa {
                    display: block;
                    font-size: em(80, 15);
                }
            }
        }
    }
}


//##############################################################################
//                                                                             #
//	! 3 CONTENT -> LAYOUT                                                      #
//                                                                             #
//##############################################################################

#content {
    @include span-columns(12);
    position: relative;
}

.content-info-side,
.content-area {
    @include span-columns(12);
    border-top: $border;
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> ADVERTS                                                   #
//                                                                             #
//##############################################################################

#ad-footer {
    @include span-columns(12);
    border-top: $border;
}

#ad-side {
    @include span-columns(12);
    border-top: $border;
}

.ad-block {
    text-align: center;

    .wrap {
        padding: 20px;
    }

    img,
    iframe {
        max-width: 100%;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> ALERTS                                                    #
//                                                                             #
//##############################################################################

.alert {
    @include span-columns(12);
    border-top: 1px solid white;
    background: $alertcolor;

    &.alert-success {
        background: $successcolor;
    }

    .wrap {
        position: relative;
        padding: 20px;
        padding-right: 56px;
        color: $white;
        font-weight: bold;
    }

    a {
        color: #fff;
        text-decoration: underline;
    }

    .close {
        position: absolute;
        top: 50%;
        margin-top: -14px;
        right: 20px;

        border: none;
        background: transparent;
        color: $white;
        font-size: em(28);
        line-height: 22px;
        padding: 0;
    }

    &.alert-welcome {
        background: $primarycolor;
        border-top: none;

        .wrap {
            font-size: .9em;
            padding: 10px 20px;
            font-weight: normal;
        }

        a {
            color: $white;
            text-decoration: underline;
        }
    }

    &.alert-homepage {
        border-top: 1px solid white;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> ARTFORM ICONS                                             #
//                                                                             #
//##############################################################################

#menu-artform {
    padding: 20px;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        @extend .clearfix;
    }

    li,
    li a {
        box-sizing: border-box;
    }

    li {
        float: left;
        width: 50%;
        margin: 0;
        padding: 0;
        border-top: 1px solid $lightestneutralcolor;
        border-right: 1px solid $lightestneutralcolor;

        &:nth-child(even) {
            border-right: 0;
        }

        &.i1,
        &.i2 {
            border-top: 0;
        }
    }

    .explore {
        height: 88px;
        text-align: center;
        padding-top: (((88 - 49) / 2) - 2 * 1px);

        b {
            display: block;
            width: 96px;
            margin: 0 auto;
            text-align: left;
            font-weight: normal;
            font-family: $altfontfamily;
            text-transform: uppercase;
            color: $secondarycolor;
            font-size: em(24);
            line-height: 1;
        }

        .fa {
            margin-left: 10px;
        }
    }

    .icon {
        a {
            //background: pink;
            display: block;
            padding: 12px 0;
            color: $midneutralcolor;
            text-align: center;
            transition: all .5s;

            &:hover {
                .icon-svg svg,
                .afi-colorpath {
                    fill: $primarycolor;
                }

                .icon-title {
                    color: $primarycolor;
                }
            }
        }

        .icon-svg {
            //background: red;
            display: block;
            width: 40px;
            height: 40px;
            margin: 0 auto;

            svg {
                display: block;
                width: 40px;
                height: 40px;
                fill: $midneutralcolor;
                transition: fill .5s;
            }
        }

        .afi-colorpath {
            fill: $midneutralcolor;
            transition: fill .5s;
        }

        .icon-title {
            display: block;
            width: 100%;
            font-family: $baseheadingfont;
            font-size: em(14);
            line-height: 1.2;
            height: 16px;
            font-weight: bold;
            transition: .5s;
            margin-top: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> BLOCKS                                                    #
//                                                                             #
//##############################################################################

.block-section {
    clear: both;
}

.block-inner {
    @extend .clearfix;
    padding: 20px;
    background: $white;
}

.block-title {
    @extend .clearfix;
    margin-bottom: 15px;

    h2,
    .block-heading {
        float: left;
        margin: 0;
        font-family: $altfontfamily;
        font-weight: normal;
        font-size: em(30);
        line-height: 1;
        color: $secondarycolor;

        small {
            font-size: 75%;
            color: $lightneutralcolor;
        }

        &.brandingAA {
            color: $aa-primarycolor;
        }
    }

    &.alt-color h2 {
        color: $primarycolor;
    }

    a {
        display: block;
        float: right;
        font-size: em(12);
        text-transform: uppercase;
        margin-top: 3px;

        i {
            margin-left: 10px;
            font-size: em(24, 12);
            vertical-align: middle;
            position: relative;
            top: -2px;
        }
    }

    p {
        color: $neutralcolor;
        float: left;
        clear: both;
        margin: 10px 0 0;
    }
}

.block-intro {
    margin: 0 0 10px 0;
    color: $neutralcolor;
    font-size: em(14);
}

.item-feature {
    margin-bottom: -10px;

    h3 {
        font-weight: bold;
        font-size: em(20);
        line-height: 1.3;
        margin: 0 0 10px 0;

        a {
            color: $textcolor;
        }
    }

    .item-thumb {
        position: relative;
        margin: 0 -20px 15px;

        a {
            position: relative;
            display: block;

            .fa {
                position: absolute;
                bottom: 10px;
                left: 20px;
                font-size: em(40);
                color: #fff;
            }
        }

        img {
            width: 100%;
        }
    }

    .item-summary {
        p {
            margin: 0 0 10px 0;
            color: $neutralcolor;
            font-size: em(14);
        }
    }
}

.block-user {
    .item-profile {
        display: table-row;
    }

    .item-thumb {
        display: table-cell;
        vertical-align: top;

        img {
            width: 120px;
            height: 120px;
        }
    }

    .item-info {
        display: table-cell;
        vertical-align: top;
        padding-left: 20px;

        h3 {
            margin: 0 0 5px 0;
            font-size: em(20);
            color: $secondarycolor;
            font-weight: 500;
        }

        h4 {
            font-weight: bold;
            font-size: em(20);
            line-height: 1.2;
            margin: 0 0 5px 0;
        }

        p {
            color: $neutralcolor;
            font-size: em(14);
        }
    }

    .item-cta {
        color: $secondarycolor;
    }

    .content-counts {
        margin: 10px 0 5px;
    }

    .count {
        display: inline-block;
        font-family: $altfontfamily;
        color: $neutralcolor;
        line-height: 1;
        margin-right: 20px;

        .num {
            display: block;
            font-size: em(30);
            margin-bottom: -2px;
        }

        .name {
            color: $lighterneutralcolor;
            font-size: em(20);
        }
    }
}

.block-about {
    ul {
        list-style: none;
        margin: 20px 0 0 0;
        padding: 0;
    }

    li {
        display: block;
        float: left;
        width: 100%;
        border-bottom: 1px solid $lightestneutralcolor;
        padding-bottom: 15px;
        margin-bottom: 15px;

        &.discover {
            border-bottom: none;
            padding-bottom: 10px;
            margin-bottom: 0;
        }

        &.silver b {
            color: #ed2127;
        }

        &.bronze b {
            color: #af7bb5;
        }

        &.explore b {
            color: #00b299;
        }

        &.discover b {
            color: #f58220;
        }

        b,
        .whatis,
        .viewposts {
            float: right;
            width: 50%; //35
            line-height: 1;
        }

        b {
            font-family: $baseheadingfont;
            font-size: em(30);
            color: $tertiarycolor;
            width: 50%; //38
            float: left;
        }

        a {
            color: $lightneutralcolor;
            font-size: em(12);
            padding: 6px 0;

            i {
                font-size: em(20, 12);
                margin-right: 3px;
                vertical-align: middle;
                position: relative;
                top: -1px;
            }
        }

        .viewposts,
        .whatis {
            //width: 27%;
            //text-align: right;
            clear: right;
        }
    }
}

.block-popular {
    padding: 0;

    .block-popular-inner {
        border: 1px solid $secondarycolor;
    }

    .block-menu {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        li {
            display: inline;
        }

        a {
            float: left;
            background: $secondarycolor;
            font-family: $altfontfamily;
            font-weight: normal;
            color: #fff;
            font-size: em(26);
            line-height: 1;
            width: 50%;
            padding: 18px 0 15px;
            text-transform: uppercase;
            text-align: center;
        }

        .active a {
            background: $white;
            color: #000;
        }
    }

    .block-section {
        clear: both;
        border: none;
    }

    .block-section-favourites,
    .block-section-events {
        display: none;
    }

    .block-title {
        padding-top: 32px;

        h2 {
            float: none;
            color: $white;
            text-align: center;
            line-height: 1;
        }
    }

    .item-list {
        list-style: none;
        margin: 0;
        padding: 5px 0;

        li {
            padding: 15px 20px;
            text-align: center;
        }

        h3 {
            margin: 0 0 5px 0;
            font-size: em(18);
            font-weight: bold;
            transition: color 0.4s;
        }

        .item-date,
        .item-author {
            display: block;
            color: $lighterneutralcolor;
            font-size: em(13);
        }

        .item-author {
            text-transform: uppercase;
        }

        a:hover {
            h3 {
                color: $secondarycolor;
            }
        }
    }
}

.block-tweets {
    .block-title {
        margin-bottom: 0;
        padding-bottom: 5px;

        h2,
        a {
            color: $tw;
        }
    }

    .item-list {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        @extend .clearfix;
        display: block;
        background: #7fd6f7;
        background: lighten($tw, 5%);
        padding: 10px;
        padding-bottom: 6px;
        margin-top: 10px;

        p {
            margin: 0 0 15px 0;
            font-size: em(12);
            color: $textcolor;
        }

        .item-date {
            float: right;
            width: auto;
            text-align: right;
            margin-top: 6px;
            font-size: em(12);
            line-height: 1;
            font-family: $baseheadingfont;
        }

        a {
            color: $white;
        }

        .item-actions {
            float: left;
            width: auto; //102

            a {
                font-size: em(15);
                margin-right: 16px;
            }
        }
    }
}


.block-author,
.block-comments,
.block-comment-form {
    border-top: $border;
    padding: 20px;
    padding-bottom: 30px;

    .block-title {
        margin-bottom: 20px;
    }

    h2 {
        color: $secondarycolor;
    }
}

.block-author {
    h2,
    .block-heading {
        color: $primarycolor;
    }

    .block-title {
        margin-bottom: 15px;
    }

    .image-profile {
        display: table-cell;
        vertical-align: top;

        img {
            width: 70px; //140
            height: 70px; //140
            background: $primarycolor;
        }
    }

    .item-info {
        display: table-cell;
        vertical-align: top;
        padding-left: 40px;
        padding-top: 0px;

        h3 {
            margin: 0 0 5px 0;
            font-size: em(20);
            font-weight: normal;
        }

        p {
            margin: 0 0 15px 0;
            color: $midneutralcolor;
            font-size: em(13);
        }
    }

    .profile-social {
        a {
            margin-right: 8px;

            .fa {
                font-size: em(18);
            }
        }
    }

    .user-badge {
        font-size: em(12, 20);
    }

    .item-cta {
        font-size: em(15);
        color: $tertiarycolor;
    }
}

.block-authormore {
    margin: 20px -20px -30px;
    border-top: $border;
    padding: 20px;
    padding-bottom: 0;

    .more-posts {
        display: none;
        margin-bottom: 20px;
    }

    .has-more-posts {
        display: inline-block;
    }
}

.block-profile {
    border: none;

    h1 {
        margin: 0px 0 20px 0;
        font-size: em(34);
        font-weight: bold;
        line-height: 1;
    }

    .user-badge {
        font-size: em(12, 34);
    }

    .item-info {
        p {
            font-size: em(15);
        }
    }

    .profile-social {
        margin: 30px 0;

        a {
            display: block;
            margin: 0 0 15px 0;
            color: $midneutralcolor;

            .fa {
                font-size: em(22);
                vertical-align: middle;
                margin-right: 2px;
            }

            span {
                font-size: em(14);
                font-weight: bold;
            }
        }
    }

    .profile-meta {
        list-style: none;
        margin: 30px 0 0 0;
        padding: 0;

        li {
            @extend .clearfix;
            margin: 0 0 15px 0;
            font-size: em(13);
            color: $midneutralcolor;
        }

        b {
            display: block;
            font-weight: normal;
        }
    }

    .profile-logos {
        margin-top: 20px;

        img {
            display: block;
            margin-bottom: 15px;
            max-width: 150px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .profile-counts {
        list-style: none;
        margin: 15px 0 -20px 0;
        padding: 0;

        li {
            margin: 0 0 20px 0;
            color: $midneutralcolor;
            font-size: em(25);
            line-height: 1;
            font-family: $altfontfamily;

            &:first-child {
                margin-left: 0;
            }
        }

        span {
            font-size: em(50, 25);
        }
    }
}

@keyframes bounce-in-fwd {
    0% {
        transform: scaleY(0);
        animation-timing-function: ease-in;
        opacity: 0;
    }
    38% {
        transform: scaleY(1);
        animation-timing-function: ease-out;
        opacity: 1;
    }
    55% {
        transform: scaleY(0.7);
        animation-timing-function: ease-in;
    }
    72% {
        transform: scaleY(1);
        animation-timing-function: ease-out;
    }
    81% {
        transform: scaleY(0.84);
        animation-timing-function: ease-in;
    }
    89% {
        transform: scaleY(1);
        animation-timing-function: ease-out;
    }
    95% {
        transform: scaleY(0.95);
        animation-timing-function: ease-in;
    }
    100% {
        transform: scaleY(1);
        animation-timing-function: ease-out;
    }
}

.block-donate{
    overflow: hidden;
    margin-top: 20px;

    .js & {
        .supportdonate{
            transform: scaleY(0);
            transform-origin: 0 0;
        }

        &.show{
            .supportdonate{
                animation: bounce-in-fwd .85s;
                transform: scaleY(1);
            }
        }
    }

    .supportdonate{
        display: flex;
        flex-direction: column;
        padding: 30px;
        background: $primarycolor;
        color: $white;

        &__text{
            p{
                margin: 0 auto;
                font-size: em(24);
                line-height: 1.3;
                font-weight: bold;
                text-align: center;
                max-width: 320px;
            }
        }

        &__btn{
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;

            .btn{
                display: block;
                padding: 5px 60px;
                background: darken($primarycolor, 10%);
                color: $white;
                font-family: $altfontfamily;
                font-size: em(34);
                font-weight: bold;
                margin: 30px 0 20px 0;
                transition: .3s;
                white-space: nowrap;

                &:hover{
                    background: $white;
                    color: $primarycolor;
                }
            }

            .other{
                display: inline-block;
                padding: 10px 0;
                margin: -10px 0;
                color: mix($primarycolor, $white, 20%);
                font-size: em(16);
                font-style: italic;
                transition: color .2s;
                white-space: nowrap;

                .fa{
                    margin-left: .5em;
                    font-size: em(15);
                }

                &:hover{
                    color: $white;
                }
            }
        }
    }
}

.block-posts,
.block-section {
    border-top: $border;
}

.block-videos {
    .block-title {
        margin-bottom: 0;
    }
}

.block-map {
    .map-area {
        padding-top: 100%;
        background: #eee;
        position: relative;
    }

    #gmap {
        position: absolute !important;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }

    .map-address {

        div {
            padding: 16px 20px;
        }

        font-size: em(14);
        line-height: 1.7;
        color: $midneutralcolor;
    }
}

.block-title-intro {
    padding: 20px;

    h1 {
        font-weight: bold;
        font-size: em(34);
        margin: 0;
    }

    p {
        color: $neutralcolor;
    }
}

.block-recentcomments {
    padding-bottom: 20px;

    .block-title {
        margin: 0;
    }

    h2 {
        color: $secondarycolor;
        margin: 20px;
    }
}

.block-notice {
    margin: 20px;
    padding: 15px 20px;
    background: $primarycolor;
    color: $white;

    p {
        margin: 0;
        font-size: em(12);
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> BLOCKS -> VOICEBOX CAROUSEL                               #
//                                                                             #
//##############################################################################

.page-wrap .voicebox-carousels {
    width: 100%;
    background: $white;

    .block-voiceboxes {
        margin: 0 -20px;
    }

    .item-title {
        h3 {
            font-size: em(24);
            padding-right: 0;
        }

        p {
            font-size: em(15);
            color: $midneutralcolor;
        }
    }

    &:first-child .block-voiceboxes .block-item:first-child {
        padding-top: 0;
    }
}

.block-voiceboxes {
    padding: 0 0 20px;

    .block-title {
        padding: 0px;
        margin-bottom: 0px;

        h2,
        a {
            color: $primarycolor;
        }

        span {
            display: none;
        }
    }

    .block-item {
        padding-top: 30px;
    }

    .block-cta {
        margin-top: -15px;
    }

    .item-title {
        //display: table;
        padding: 0 20px 10px;

        h3,
        p {
            //display: table-cell;
            vertical-align: middle;
            margin: 0;
        }

        h3 {
            white-space: nowrap;
            font-size: em(20);
            font-weight: bold;
            padding-right: 20px;

            a {
                color: $textcolor;
            }
        }

        p {
            font-size: em(14);
            color: $neutralcolor;
        }
    }

    .item-list {
        margin: 0;
        padding: 0 50px;

        .glide__slides {
            margin: 0;

            a {
                border-right: 0;
            }
        }

        .no-js & {
            .glide__slide {
                max-width: 312px;
                margin-right: 1px;
            }
        }

        a {
            display: block;
            position: relative;
            border-right: 1px solid $white;
            background: $black;

            &:hover img {
                filter: alpha(opacity=100);
                opacity: 1;
            }
        }

        h4 {
            position: absolute;
            z-index: 2;
            top: 16px;
            right: 30px;
            left: 20px;
            margin: 0;
            font-size: em(20);
            line-height: 1.2;
            font-weight: bold;
            color: $white;
            text-shadow: 1px 1px 6px $black;
            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;
        }

        img {
            width: 100%;
            filter: alpha(opacity=60);
            opacity: 0.6;
            transition: all 0.4s;
        }

        .post-author-level,
        .post-template-icon {
            position: absolute;
            right: 5px;

            img {
                display: block;
                width: auto;
                max-width: none;
                filter: alpha(opacity=100);
                opacity: 1;
            }
        }

        .post-author-level {
            bottom: 5px;

            img {
                height: 20px;
            }
        }

        .post-template-icon {
            top: 5px;

            img {
                height: 18px;
            }
        }

        .viewall {
            a {
                transition: all 0.4s;

                &:hover {
                    background: $primarycolor;
                }
            }

            h4 {
                text-align: center;
                top: 50%;
                margin-top: -43px;
                font-size: em(28);
            }

            i {
                display: block;
                margin-top: 4px;
                font-size: em(40, 28);
            }

            img,
            a:hover img {
                filter: alpha(opacity=20);
                opacity: 0.2;
            }
        }
    }

    .owl-next,
    .owl-prev,
    .glide__arrow {
        appearance: none;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: -50px;
        width: 50px;
        background: $primarycolor;
        color: $white;
        text-align: center;
        border: 0;
        border-right: 1px solid #fff;

        i {
            font-size: em(36);
            position: absolute;
            top: 50%;
            //left: 50%;
            margin-top: -18px;
            //margin-left: -15px;
            left: 0px;
            width: 50px;
            transition: .2s;
        }

        &.disabled {
            opacity: 0.5;
            cursor: auto;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &:hover {
            i {
                transform: scale(1.2);
            }
        }
    }

    .owl-next,
    .glide__arrow--right {
        left: auto;
        right: -50px;
        width: 50px;
        border-right: none;
    }
}

.templateAA {
    .block-voiceboxes {
        .owl-next,
        .owl-prev {
            background: $aa-primarycolor;

            &.disabled {
                color: tint($aa-primarycolor, 50%);
            }
        }
    }
}

.templateTR {
    .block-voiceboxes {
        .owl-next,
        .owl-prev {
            background: $tr-primarycolor;

            &.disabled {
                color: tint($tr-primarycolor, 50%);
            }
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> BREADCRUMB/BUTTONS                                        #
//                                                                             #
//##############################################################################

#title-bar {
    @include span-columns(12);
    border-top: $border;

    .breadcrumbs {
        padding: 7px 20px; //10 20

        a {
            font-family: $altfontfamily;
            font-size: em(24); //30
            color: $secondarycolor;
            vertical-align: middle;
        }

        .templateTR &  {
            a, .fa {
                color: $tr-secondarycolor;
            }
        }

        .fa {
            font-size: em(13); //15
            color: $secondarycolor;
            padding: 0 10px; //0 17
        }
    }

    .buttons {
        //display: none;
        padding: 7px 20px; //10 20
        padding-top: 0;

        button,
        .fake-btn {
            background: none;
            border: none;

            display: inline-block;
            font-size: em(11); //12
            color: $secondarycolor;
            font-weight: bold;
            text-transform: uppercase;
            padding: 0 10px 0 0;
            margin: 0 0 0 30px;

            &:first-child {
                margin-left: 0;
            }

            .fa {
                font-size: em(20, 11); //30
                vertical-align: middle;
                margin-right: 6px;
            }
        }

        /*.js & {
			display: block;
		}*/

        .fake-btn {
            //color: $primarycolor;
        }

        .btn-switch-view {
            display: none;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> COMMENTS                                                  #
//                                                                             #
//##############################################################################

.list-comments {
    list-style: none;
    margin: 0;
    padding: 10px 0 0 0;

    li {
        display: table;
        margin-top: 30px;

        &:first-child {
            margin-top: 0px;
        }

        &:hover {
            .comment-btn .btn {
                filter: alpha(opacity=100);
                opacity: 1;
            }
        }
    }

    .image-profile {
        display: table-cell;
        vertical-align: top;

        img {
            width: 70px;
            height: 70px;
        }
    }

    .item-comment {
        display: table-cell;
        vertical-align: top;
        padding-left: 20px;
    }

    .comment-meta {
        margin: 0;
        font-family: $baseheadingfont;
        font-size: em(16);
        color: $lightneutralcolor;
    }

    .comment-text {
        p {
            margin: 12px 0 0 0;
            color: $midneutralcolor;
            font-size: em(14);
        }
    }

    .comment-btn {
        margin-top: 20px;

        form {
            display: inline;
            margin-right: 15px;
        }

        .btn {
            background: $white;
            color: $secondarycolor;
            font-family: $baseheadingfont;
            font-weight: bold;
            font-size: em(13);
            padding: 6px 6px;
            border: none;
            margin: 0;
            text-transform: lowercase;
            filter: alpha(opacity=0);
            opacity: 0;
            transition: opacity 0.4s;


            &:hover {
                color: lighten($secondarycolor, 15%);
            }

            .fa {
                margin-right: 4px;
            }
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> FILTER                                                    #
//                                                                             #
//##############################################################################

.block-title .filter-menu {
    float: left;
    margin-top: 15px;

    b,
    ul {
        float: left;
    }
}

.block-filter {
    border-bottom: $border;

    h2 {
        float: none;
    }

    .filter-menu {
        float: none;
        margin-top: 0;
        margin-bottom: -10px;
    }
}

.filter-menu {
    overflow: auto;

    + .filter-menu {
        border-top: 1px solid $lightestneutralcolor;
        margin-top: 15px;
        padding-top: 15px;
    }

    b {
        display: block;
        font-weight: normal;
        color: $neutralcolor;
        text-transform: uppercase;
        font-size: em(12);
        margin-bottom: 10px;
        margin-right: 15px;
        padding: .275em 0;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        display: inline;
    }

    a {
        display: inline-block;
        float: left;
        font-size: em(12);
        margin: 0 10px 10px 0;
        padding: .2em 1em;
        border-radius: 9em;
        border: 1px solid $primarycolor;
        text-transform: uppercase;
        color: $midneutralcolor;
    }

    .active a,
    a.active,
    a:hover {
        background: $primarycolor;
        color: $white;
    }

    .brandingAA {
        a {
            border-color: $aa-primarycolor;
            color: $midneutralcolor;
        }

        &.active a,
        .active a,
        a.active,
        a:hover {
            background: $aa-primarycolor;
            color: $white;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> FORM                                                      #
//                                                                             #
//##############################################################################

.form {
    .row {
        margin-bottom: 20px;
    }

    &.form-page {
        .row {
            margin-bottom: 30px;
        }
    }

    label {
        display: block;
        font-family: $baseheadingfont;
        line-height: 1.1;
        color: $neutralcolor;
        font-size: em(15);
        padding: 4px 0 8px;
        font-weight: bold;
    }

    .form-label-required {
        color: $primarycolor;
    }

    input,
    select,
    textarea {
        //box-sizing: content-box;
        box-sizing: border-box;
        border: 1px solid $lightestneutralcolor;
        //background-color: $lightestneutralcolor;
        background-color: #fff;
        color: $textcolor;
        border-radius: 3px;
        box-shadow: none;
        padding: 8px;
        font-size: em(15);
        width: 100%;
        font-family: Arial, sans-serif;

        &:focus {
            background-color: $white;
            border-color: $primarycolor;
            outline: none;
        }
    }

    textarea {
        padding: 10px;
        height: 120px;
        line-height: 1.4;
    }

    @media (-webkit-min-device-pixel-ratio: 0) {
        select,
        select[size="0"],
        select[size="1"] {
            @include bg('main/form-select-arrow.png', right, center);
            padding-right: 32px;
        }
    }

    .row-checkbox {
        padding: 3px 0;

        label {
            font-weight: normal;
            display: inline-block;
            padding: 4px 0;
        }

        input {
            width: auto;
            padding: 0;
            margin-right: 6px;
        }

        .long {
            position: relative;
            padding-left: 22px;
            line-height: 1.4;
            display: block;

            input {
                position: absolute;
                left: 0px;
                top: 10px;
            }
        }

        .text-input {
            padding: 6px;
            margin-right: 0;
            margin-top: -2px;
            margin-left: 10px;
            font-size: em(14);
        }
    }

    .submit,
    .row-submit button {
        background: $primarycolor;
        color: $white;
        font-family: $baseheadingfont;
        font-weight: bold;
        font-size: em(15);
        padding: 6px 50px;
        border: none;
        margin: 10px 0 0 0;
        text-transform: uppercase;
        border-radius: 0px;
        text-shadow: none;
        text-align: center;

        &:hover {
            background: darken($primarycolor, 15%);
        }

        &:focus {
            background-color: $primarycolor;
        }

        &.cancel {
            background: $neutralcolor;

            &:hover {
                background: darken($neutralcolor, 15%);
            }

            &:focus {
                background-color: $neutralcolor;
            }
        }

        &[disabled] {
            cursor: not-allowed;
            opacity: 0.2;

            &:hover {
                background-color: $primarycolor;
            }
        }
    }

    .help-block,
    .help-info {
        display: inline-block;
        margin-top: 6px;
        font-size: em(13);
        font-style: italic;
        color: $neutralcolor;

        &:empty {
            display: none;
        }
    }

    .help-info {
        display: block;
    }
}

.parsley-errors-list {
    display: none;
    margin: 0;
    padding: 0;
    list-style: none;

    &.filled {
        display: block;
    }
}

.parsley-errors-list li,
.form .has-error .help-block,
.error-text {
    display: inline-block;
    margin: 8px 0 0 0;
    padding: .1em .4em;
    font-size: em(13);
    font-weight: normal;
    font-style: normal;
    background: $alertcolor;
    color: $white;
}

.level-cb {
    display: inline-block;
    width: 140px;
}

.form .level-date {
    display: inline-block;

    div {
        display: inline-block;
        font-size: 0.8em;
        margin-bottom: 10px;
        vertical-align: top;
    }

    select {
        vertical-align: middle;
        width: auto;
    }

    .level-start {
        margin-right: 20px;
    }

    .help-block {
        display: table;
    }
}

.form-aside {
    padding: 0 20px;
    margin-bottom: 20px;

    .row {
        //background: yellow;
        margin-bottom: 10px;
    }

    input,
    select,
    textarea {
        &:focus {
            border-color: $secondarycolor;

            .aside-alt & {
                border-color: $primarycolor;
            }
        }
    }

    .row-checkbox {
        .badge {
            display: inline-block;
            float: right;
            font-size: em(10);
            background: $lightestneutralcolor;
            font-weight: bold;
            color: $neutralcolor;
            text-align: center;
            padding: .2em 0;
            min-width: 30px;
            margin-top: 3px;
            border-radius: 1em;
        }

        &:hover {
            .badge {
                background: $neutralcolor;
                color: $white;
            }
        }
    }

    .row-submit {
        margin: 0;

        button {
            width: 100%;
            margin: 0;
            padding-left: 0;
            padding-right: 0;
            background: $secondarycolor;

            &:hover {
                background: lighten($secondarycolor, 15%);
            }

            &:focus {
                background-color: $secondarycolor;
            }

            .aside-alt & {
                background: $primarycolor;

                &:hover {
                    background: darken($primarycolor, 15%);
                }

                &:focus {
                    background-color: $primarycolor;
                }
            }
        }
    }
}

.form-fav {
    display: inline;

    button {
        background: none;
        border: none;
    }
}

.form-loadingOverlay {
    position: relative;

    .form-loadingOverlay {
        display: none;
        position: absolute;
        z-index: 10;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
        background: rgba(#fff, 0.5);
    }

    &.form-activateOverlay {
        .form-loadingOverlay {
            display: block;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> FORM -> COMMENT                                           #
//                                                                             #
//##############################################################################

.block-comment-form {
    padding-bottom: 20px;
    position: relative;

    .profile-select {
        width: 100%;
        margin-bottom: 10px;
    }

    .form-comment {
        //display: table;
    }

    .image-profile {
        display: none;
        vertical-align: top;

        img {
            width: 70px;
            height: 70px;
        }
    }

    .comment-field {
        //display: table-cell;
        vertical-align: top;
        //padding-left: 20px;
        width: 100%;
    }

    label {
        display: block;
        font-family: $baseheadingfont;
        font-size: em(16);
        color: $lightneutralcolor;
        margin: 0 0 6px 0;
        font-weight: normal;

        b {
            font-weight: normal;
            color: $primarycolor;
        }
    }

    textarea {
        width: 100%;
        height: 180px;
    }

    .row-checkbox {
        margin-top: 8px;
        padding: 0;

        label {
            margin-bottom: 0;
            padding: 0;
            font-size: em(14);
        }
    }

    .error-text {
        margin: 4px 0 10px 0;
    }

    .help-link {
        color: $lightneutralcolor;
        font-size: em(12);
        float: right;
        margin-top: 4px;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> FORM -> VOICEMAIL                                         #
//                                                                             #
//##############################################################################

.block-voicemail {
    .block-inner {
        margin: 20px;
        background: lighten($primarycolor, 20%);
        padding-top: 17px;

        p {
            color: $white;
            margin: 15px 0 0 0;
        }
    }

    .block-title {
        margin-bottom: 6px;

        h2 {
            color: $white;

            small {
                display: block;
                color: $white;
                color: $primarycolor;
            }
        }
    }

    .form {
        .row {
            margin-bottom: 0;
        }

        input {
            border-color: $primarycolor;
        }
    }
}

#newsletter-homepage {
    .block-inner {
        margin: 0;
        padding: 40px 20px;
        background: $primarycolor;
        text-align: center;
    }

    .block-title {
        h2 {
            display: block;
            float: none;
            font-size: em(30);
        }

        p {
            display: block;
            float: none;
            color: lighten($primarycolor, 30%);
            margin: 5px 0;
        }
    }

    .form {
        .row {
            margin: 10px 0;
        }

        input {
            background: lighten($primarycolor, 10%);
            color: $white;

            &::placeholder {
                color: $white;
            }
        }

        button {
            background: $white;
            color: $primarycolor;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> LISTING                                                   #
//                                                                             #
//##############################################################################


.listing-image-grid {
    .post-img {
        position: relative;
        background: $black;

        img {
            width: 100%;
            opacity: 0.6;
            transition: all 0.4s;
        }

        .page-wrap & {
            margin: 0;
        }
    }

    .post-author-level {
        position: absolute;
        right: 5px;
        bottom: 5px;

        img {
            display: block;
            height: 20px;
        }
    }

    .post-template-icon {
        position: absolute;
        right: 5px;
        top: 5px;

        img {
            display: block;
            height: 18px;
        }
    }

    span,
    .post-title {
        position: absolute;
        font-family: $baseheadingfont;
    }

    span {
        font-size: em(12);
        line-height: 1;
        text-transform: uppercase;
        padding: 2px 10px 2px 20px;
        left: 0px;
        background: $white;
        color: $textcolor;
    }

    .post-type {
        top: 15px;
    }

    .content-ugc .post-type,
    .post-type.type-ugc {
        background: $secondarycolor;
        color: $white;
    }

    .content-mag .post-type,
    .post-type.type-mag {
        background: $primarycolor;
        color: $white;
    }

    .post-author {
        bottom: 36px;

        .user-badge {
            position: relative;
            padding: 0 8px 1px;
            background: $primarycolor;
            color: $white;
        }
    }

    .post-date {
        bottom: 15px;
    }

    .post-comments {
        left: auto;
        right: 0px;
        bottom: 15px;
        padding-left: 10px;
        padding-right: 20px;

        i {
            padding-right: 5px;
            font-size: em(11, 12);
            line-height: 9px;
            position: relative;
            top: -1px;
        }
    }

    .post-myv-1,
    .post-myv-2 {
        left: auto;
        right: 0px;
        bottom: 15px;
        padding-left: 10px;
        padding-right: 20px;
    }

    .post-myv-1 {
        bottom: 36px;
    }

    .post-title {
        margin: 0;
        top: 36px; //60
        left: 20px;
        right: 20px;
        height: 74px; //97
        overflow: hidden;
        font-size: em(20); //26
        line-height: 1.2;
        font-weight: bold;
        color: $white;
        text-shadow: 1px 1px 5px $black;
    }

    &.no-footer {
        .post-title {
            height: 122px;
        }
    }

    .type-theme {
        overflow: hidden;
        padding-bottom: 20px;
        text-align: center;

        h2 {
            margin: 0;
            background: $tertiarycolor;
            color: $white;
            font-weight: bold;
            font-size: em(18);
            padding: 2px 20px;
            text-align: left;
        }

        p {
            margin: 0;
            padding: 10px 20px;
            color: $midneutralcolor;
            font-size: em(16);
            line-height: 1.5;
            text-align: left;
        }

        a {
            display: inline;
            color: $tertiarycolor;
            font-family: $baseheadingfont;
            font-weight: bold;
            font-size: em(16); //18

            &.cta {
                padding: 0 20px;
            }
        }
    }

    &.feature-content {
        .post-title {
            top: 36px;
        }
    }
}

.type-ribbon {
    font-size: em(12);
    line-height: 1;
    text-transform: uppercase;
    padding: 2px 10px 2px 20px;
    background: $primarycolor;
    color: $white;

    &.type-ugc,
    .type-ugc & {
        background: $secondarycolor;
    }
}

.content-list {
    list-style: none;
    margin: 0;
    padding: 20px;

    @extend .clearfix;

    .post-author-level,
    .post-template-icon,
    .post-img i.post-author-level,
    .post-img i.post-template-icon {
        position: absolute;
        right: 5px;

        img {
            display: block;
            width: auto;
            max-width: none;
            opacity: 1;
        }
    }

    .post-author-level {
        bottom: 5px;

        img {
            height: 20px;
        }
    }

    .post-template-icon {
        top: 5px;

        img {
            height: 18px;
        }
    }
}

.content-list.listing-image-grid {
    padding: 0 0 20px 0;

    li {
        padding: 0;
    }

    .post-cont {
        position: relative;
        border-bottom: 1px solid $white;
    }

    .item-summary,
    .item-cta {
        display: none;
    }

    span {
        z-index: 9;
    }

    a {
        display: block;

        &:hover {
            img {
                opacity: 1;
            }
        }
    }

    .post-info {
        padding: 0;
    }

    .post-title,
    .post-author {
        a {
            border: none;
            color: $white;

            &:after {
                content: none;
            }
        }
    }

    .post-author {
        a {
            display: inline;
            color: $textcolor;
        }
    }

    &.alt-videos {
        a {
            display: block;

            &:hover {
                img {
                    opacity: 0.2;
                }

                .post-info {
                    display: block;
                }
            }
        }

        .post-img {
            background: $white;

            img {
                opacity: 1;
            }
        }

        .post-info {
            position: absolute;
            top: 0px;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 20px;
            padding-bottom: 60px;
            display: none;
        }

        .post-title {
            color: $black;
            text-shadow: none;
            height: auto;
            position: static;
            font-size: em(20);
            margin-bottom: 5px;
        }

        .post-author {
            position: static;
            padding: 0;
            background: none;
            text-transform: none;
            font-size: em(14);
        }

        .item-cta {
            display: block;
            position: absolute;
            left: 20px;
            bottom: 20px;
            color: $secondarycolor;
        }
    }
}

.content-list.listing-full-info {
    li {
        margin: 40px 0 0 0;

        &:first-child {
            margin-top: 0;
        }
    }

    .post-img {
        width: 100%;
        max-width: 300px;
        position: relative;

        img {
            width: 100%;
            max-width: 300px;
        }
    }

    .post-type {
        position: absolute;
        top: 10px;
        z-index: 9;
        font-size: em(12);
        line-height: 1;
        text-transform: uppercase;
        padding: 2px 10px 2px 20px;
        left: 0px;
        background: $primarycolor;
        color: $white;

        &.type-ugc {
            background: $secondarycolor;
        }

        &.type-badge {
            top: auto;
            bottom: 10px;
        }
    }

    .post-info {
        padding-top: 6px;
    }

    .post-date {
        font-family: $baseheadingfont;
        font-size: em(13);
        color: $lightneutralcolor;
        text-transform: uppercase;
    }

    .post-title {
        font-family: $baseheadingfont;
        font-size: em(24);
        line-height: 1.3;
        font-weight: bold;
        margin: 6px 0 0 0;

        a {
            color: $textcolor;
        }
    }

    .post-author {
        font-size: em(14);
        color: $lightneutralcolor;

        a {
            color: $lightneutralcolor;
        }
    }

    .item-summary {
        margin: 10px 0 0 0;
        color: $neutralcolor;
        font-size: em(14);
    }

    .item-stars {
        float: left;
        margin-right: 10px;
        color: $primarycolor;
    }

    .item-cta {
        display: inline-block;
        margin: 7px 0 0 0;
        text-transform: uppercase;
        font-size: em(14);
        font-weight: bold;
        font-family: $baseheadingfont;
        color: $primarycolor;

        i {
            margin-left: 5px;
        }
    }

    .post-comments {
        display: none;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> MODAL                                                     #
//                                                                             #
//##############################################################################

.mfp-content {
    margin: 0 auto;
    background: $white;
    box-shadow: 0 0 10px $black;
}

.mfp-inline-holder .mfp-content {
    //width: 604px;
    max-width: 80%;
    min-width: 400px;
    width: auto;
}

.mfp-close-btn-in .mfp-close {
    background: #fff;
    color: #999;

    &:hover {
        color: #000;
    }
}

.mfp-iframe-holder .mfp-close {
    background: transparent;
}

.mfp-page-type{
    .mfp-content{
        width: 90%;
        max-width: 1200px;
    }

    .mfp-iframe-scaler{
        height: auto;
        min-height: 90vh;
        padding-top: unset;
    }
}

.modal {
    padding: 20px;
    max-width: 600px;

    .block-title {
        h2 {
            color: $secondarycolor;
            font-size: em(34);
        }

        p {
            clear: both;
            font-size: em(14);
            color: $neutralcolor;
            margin: 0;
        }
    }

    .page-info {
        border-top: 1px dotted $lightestneutralcolor;
        border-bottom: 1px dotted $lightestneutralcolor;
        padding: 20px 0;
        position: relative;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> MODAL -> CONFIRM                                          #
//                                                                             #
//##############################################################################

#confirm-modal {
    .page-info {
        ul {
            margin: 0;
            padding: 0 0 0 20px;
        }

        li {
            margin: 0 0 5px;
            font-size: em(14);
        }
    }

    .action-buttons {
        margin-top: 10px;
        text-align: right;

        .btn {
            display: inline-block;
            background: $tertiarycolor;
            color: $white;
            font-family: $baseheadingfont;
            font-weight: bold;
            font-size: em(15);
            padding: 6px 50px;
            border: none;
            margin: 10px 0 0 0;
            text-transform: uppercase;
            border-radius: 0px;
            text-shadow: none;
            text-align: center;

            &:hover {
                background: darken($tertiarycolor, 15%);
            }

            &:focus {
                background-color: $tertiarycolor;
            }
        }

        .btn-cancel {
            background: $white;
            color: $neutralcolor;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> MODAL -> HUB STATUS                                       #
//                                                                             #
//##############################################################################

.hubStatus-modal {
    .mfp-content {
        margin-top: 30px;
        margin-bottom: 30px;
        max-width: 600px;
        min-width: 0px;
    }
}

#hubStatus-modal {
    .form-login {
        a,
        button {
            transition: all 0.4s;
        }

        .row {
            margin-bottom: 15px;
        }

        .row-checkbox {
            padding: 0;

            label {
                font-weight: bold;
                margin-right: 6px;
            }
        }

        .row-submit {
            button {
                margin-top: 0;
            }

            .forgot {
                float: right;
                font-size: em(12);
                margin-top: 10px;
            }
        }

        .row-social-logins {
            margin-top: 30px;

            span {
                display: block;
                font-size: em(12);
                font-weight: bold;
                text-transform: uppercase;
                color: $secondarycolor;
            }

            a {
                display: inline-block;
                margin-right: 15px;
                color: $neutralcolor;

                .fa {
                    margin-right: 3px;
                }

                &:hover {
                    color: $primarycolor;
                }
            }
        }

        .register {
            display: block;
            margin-top: 30px;
            padding: 6px 10px;
            text-align: center;
            font-weight: bold;
            line-height: 1.3;
            font-size: em(15);
            background: $secondarycolor;
            color: #fff;
            font-family: $baseheadingfont;
            text-transform: uppercase;

            &:hover {
                background: lighten($secondarycolor, 15%);
            }
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> MODAL -> REPORT                                           #
//                                                                             #
//##############################################################################

#report-modal,
#report-comment-modal {
    .row-submit {
        text-align: right;

        button {
            margin-top: 0;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> MODAL -> SHARE                                            #
//                                                                             #
//##############################################################################

#share-modal {
    .page-info {
        padding-left: 140px;
        min-height: 120px;

        .img {
            position: absolute;
            top: 20px;
            left: 0px;
            width: 120px;

            img {
                width: 100%;
            }
        }

        h3 {
            font-weight: bold;
            margin: 10px 0 2px;
            font-size: em(18);
        }

        p {
            color: $neutralcolor;
            margin: 0 0 5px 0;
            font-size: em(14);
        }

        i {
            font-size: em(13);
            font-style: italic;
            color: $primarycolor;
        }
    }

    .share-buttons {
        margin-top: 20px;
        text-align: right;

        .sharrre {
            display: inline;
        }

        a {
            display: inline-block;
            background: $primarycolor;
            color: $white;
            font-family: $baseheadingfont;
            text-transform: uppercase;
            font-size: em(15);
            font-weight: bold;
            padding: 8px 12px;
            margin-left: 10px;
        }

        .count {
            background: $white;
            font-size: em(12, 15);
            padding: 2px 12px;
            color: $neutralcolor;
            margin-left: 6px;
        }
    }

    #shb-twitter a {
        background: #55acee;
    }

    #shb-facebook a {
        background: #3a5795;
    }

    #shb-googleplus a {
        background: #cc181e;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> NOTI                                                      #
//                                                                             #
//##############################################################################

.noti {
    position: fixed;
    z-index: 99999;

    background: $white;
    box-shadow: 0 0 10px black;
    //border-top: 8px solid $primarycolor;
    border-radius: 2px 2px 0 0;
    transition: all 1s;

    width: 100%;
    bottom: -110%;
    right: 0;

    &.show {
        bottom: 0px;
    }

    .noti-close {
        position: absolute;
        top: 0px;
        right: 0px;
        border: none;
        background: $white;
        color: $primarycolor;
        font-size: em(24);
        line-height: 1;
        padding: 3px 10px 9px;
    }

    h2 {
        font-size: em(14);
        line-height: 36px;
        margin: 0 0 0 10px;
        text-transform: uppercase;
        color: $neutralcolor;
        font-weight: bold;
    }

    .noti-item {
        &:hover {
            .noti-cta {
                background: darken($primarycolor, 10%);
            }

            .noti-img {
                opacity: 1;
            }
        }
    }

    .noti-layout {
        display: table;
        margin: 10px;
        margin-top: 0px;
    }

    .noti-info {
        display: table-cell;
        vertical-align: middle;
    }

    h3 {
        line-height: 1.2;
        margin: 0;
        font-weight: bold;
        font-size: em(14);
    }

    .noti-author {
        font-size: em(12);
        color: $lightneutralcolor;
        margin: 0;
    }

    .noti-img {
        display: table-cell;
        vertical-align: middle;
        margin: 0 10px 0 0;
        opacity: 0.8;
        transition: all .5s;
        width: 40px;
    }

    .noti-cta {
        display: block;
        width: 100%;
        margin: 0;
        background: $primarycolor;
        color: $white;
        text-align: center;
        padding: 4px 0;
        transition: all .5s;
        text-transform: uppercase;
        font-size: em(13);
        font-weight: bold;
        font-family: $baseheadingfont;

        i {
            margin-left: 5px;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> PAGINATION                                                #
//                                                                             #
//##############################################################################

.pagination {
    list-style: none;
    margin: 60px 0 40px 0;
    padding: 0;
    @extend .clearfix;
    text-align: center;

    li {
        display: inline;
    }

    a,
    b {
        display: inline-block;
        background: $tertiarycolor;
        color: $white;
        border: 2px solid $tertiarycolor;
        padding: 6px 10px;
        font-weight: bold;
        font-size: em(20);
        line-height: 1;
        font-family: $baseheadingfont;
        vertical-align: middle;
        margin: 0 2px 20px 2px;
    }

    b {
        background: $white;
        color: $tertiarycolor;
    }

    a:hover {
        background: darken($tertiarycolor, 10%);
        border-color: darken($tertiarycolor, 10%);
    }

    .first a,
    .prev a,
    .next a,
    .last a {
        font-size: em(16);
        padding-top: 2px;
        padding-bottom: 2px;
        padding-left: 5px;
    }

    .fa {
        font-size: em(28, 16);
        vertical-align: middle;
        margin: 0 5px 0 0;
    }

    .next,
    .last {
        a {
            padding-left: 10px;
            padding-right: 5px;
        }

        .fa {
            margin: 0 0 0 5px;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> PROFILE                                                   #
//                                                                             #
//##############################################################################

.image-profile {
    display: inline-block;

    img {
        padding: 2px;
        background: $secondarycolor;
        border-radius: 50%;
    }

    .wrap {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 2px;
            right: 2px;
            bottom: 2px;
            left: 2px;
            box-shadow: inset 0 0 15px rgba($black, 0.5);
            border-radius: 50%;
        }
    }
}

.profile-image-field {
    .image-profile {
        display: table-cell;
        vertical-align: top;

        img {
            width: 140px;
            height: 140px;
        }
    }

    .buttons {
        display: table-cell;
        vertical-align: middle;
        padding-left: 10px;
    }

    .btn {
        background: $white;
        color: $secondarycolor;
        font-family: $baseheadingfont;
        font-weight: bold;
        font-size: em(13);
        padding: 6px 6px;
        border: none;
        margin: 0;
        text-transform: lowercase;

        &:hover {
            color: lighten($secondarycolor, 15%);
        }

        .fa {
            margin-right: 4px;
        }
    }

    .no-js & {
        .current-image {
            margin-top: 10px;
        }
    }
}

.user-badge {
    font-size: em(12);
    line-height: 1;
    color: $white;
    font-family: $baseheadingfont;
    font-weight: bold;
    background: $primarycolor;
    text-transform: uppercase;
    padding: 0 8px 1px;
    vertical-align: middle;
    position: relative;
    top: -2px;

    &.brandingAA {
        background: $aa-primarycolor;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> SEARCH BY ALGOLIA                                         #
//                                                                             #
//##############################################################################

.nav-funcs .searchByAlgolia,
.sb-search .searchByAlgolia {
    width: 120px;
    float: right;
    margin-top: 20px;
}

.explore-search {
    position: relative;

    .searchByAlgolia {
        position: absolute;
        top: 27px;
        right: 20px;
        width: 120px;
    }
}

.row-submit {
    .searchByAlgolia {
        float: right;
        width: 120px;
        margin-top: 18px;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> SLIDESHOW                                                 #
//                                                                             #
//##############################################################################

.slideshow-carousel {
    margin-top: 20px;
    border-top: 1px solid $lightestneutralcolor;

    .item {

        img {
            max-width: 100%;
            max-height: 600px;
            width: auto;
            display: inline;
        }

        .item-img {
            text-align: center;
            position: relative;
            padding-bottom: 10px;

            .custom-next,
            .custom-prev {
                display: none;
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: 0px;
                width: 40%;
                cursor: pointer;
                filter: alpha(opacity=0);
                opacity: 0;
                transition: opacity 0.4s;

                .fa {
                    position: absolute;
                    top: 50%;
                    margin-top: -30px;
                    left: 20px;
                    color: $white;
                    font-size: em(60);
                    filter: alpha(opacity=50);
                    opacity: 0.5;
                    transition: opacity 0.4s;
                }

                .custom-inner {
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    left: 0px;
                    width: 50%;
                }

                &:hover .fa {
                    filter: alpha(opacity=100);
                    opacity: 1;
                }
            }

            .custom-next {
                left: auto;
                right: 0px;

                .fa {
                    left: auto;
                    right: 20px;
                }

                .custom-inner {
                    left: auto;
                    right: 0px;
                }
            }

            &:hover {
                .custom-next,
                .custom-prev {
                    filter: alpha(opacity=100);
                    opacity: 1;
                }
            }
        }

        .item-text {
            padding: 10px 0 30px;

            p {
                font-size: em(15);
                margin: 0 0 10px 0;
            }
        }
    }

    .owl-nav {
        overflow: auto;
        padding: 5px 0;
        //float: left;
        text-align: center;
    }

    .owl-prev,
    .owl-next,
    .custom-fullscreen {
        display: inline-block;
        margin-right: 20px;
        text-transform: uppercase;
        font-size: em(14);
        font-weight: bold;
        font-family: $baseheadingfont;
        color: $tertiarycolor;
        background: $white;
        padding: 5px 20px;
        cursor: pointer;

        i {
            margin-right: 5px;
        }
    }

    .owl-next {
        margin: 0;

        i {
            margin: 0 0 0 5px;
        }
    }

    .custom-fullscreen {
        margin: 5px 0;
        float: right;
    }
}

.slideshow-carousel-nav-cont {
    display: none;
    padding: 1px 30px 1px 31px;
    margin-bottom: 40px;
    background: $lightestneutralcolor;
    background: white;
    border-top: 1px solid $lightestneutralcolor;
    border-bottom: 1px solid $lightestneutralcolor;
}

.slideshow-carousel-nav {
    .item {
        background: black;

        .item-img {
            border-right: 1px solid white;
        }

        img {
            width: 100%;
            filter: alpha(opacity=60);
            opacity: 0.6;
            transition: all 0.4s;
            border-right: 1px solid white;
        }

        &:hover {
            cursor: pointer;

            img {
                filter: alpha(opacity=100);
                opacity: 1;
            }
        }
    }

    .synced {
        img {
            filter: alpha(opacity=100);
            opacity: 1;
        }
    }

    .owl-prev,
    .owl-next {
        background: $tertiarycolor;
        width: 30px;
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: -31px;
        color: $white;
        text-align: center;

        .fa {
            position: absolute;
            top: 50%;
            margin-top: -11px;
            left: 0px;
            width: 30px;
            font-size: em(22);
        }
    }

    .owl-next {
        left: auto;
        right: -30px;
        border-left: 1px solid white;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> TABLE                                                     #
//                                                                             #
//##############################################################################

.table {
    width: 100%;
    margin: 20px 0;

    thead.nope {
        border-top: 1px solid $lightneutralcolor;
        border-bottom: 1px solid $lightneutralcolor;

        th {
            padding: 10px 4px;
            text-align: left;
            background: $lightestneutralcolor;
        }
    }

    thead {
        border-top: 1px solid $secondarycolor;
        border-bottom: 4px solid $secondarycolor;

        th {
            padding: 10px 4px;
            text-align: left;
            //background: $lightestneutralcolor;
            font-family: $baseheadingfont;
            color: $secondarycolor;
            text-transform: uppercase;
        }
    }

    tbody {
        tr {
            border-bottom: 1px solid $lighterneutralcolor;
            background: lighten($lightestneutralcolor, 8%);
        }

        td {
            padding: 8px 4px;
            font-size: em(14);
        }

        .unread {
            background: $white;

            td {
                font-weight: bold;
            }
        }
    }

    .status {
        text-align: center;

        i {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: $primarycolor;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> TOAST                                                     #
//                                                                             #
//##############################################################################

.toast {
    position: fixed;
    z-index: 99999;

    background: $white;
    box-shadow: 0 0 10px black;
    border-radius: 2px 2px 0 0;
    transition: all .5s;

    width: 100%;
    bottom: -110%;
    left: 0;
    padding: 20px 0;

    display: flex;
    flex-direction: row;
    align-items: center;

    &.show {
        bottom: 0px;
    }

    .toast__icon {
        width: 20px;
        margin-left: 20px;

        img {
            max-width: 100%;
        }
    }

    .toast__text {
        margin: 0 20px;
        text-transform: uppercase;
        color: $neutralcolor;
        font-family: $baseheadingfont;
        font-size: em(14);
        font-weight: bold;
    }
}


//##############################################################################
//                                                                             #
//	! 5.1 HOMEPAGE -> VOICE                                                    #
//                                                                             #
//##############################################################################

#category-links {

    margin: 40px 0;
    padding: 0 10px;

    .icon-title {
        float: left;
        margin: 0;
        margin-top: 15px;
        font-family: $altfontfamily;
        font-weight: bold;
        //font-size: em(30);
        font-size: em(20);
        line-height: 1;
        color: #193332;

        small {
            font-size: 75%;
            color: $lightneutralcolor;
        }

        &.brandingAA {
            color: $aa-primarycolor;
        }
    }

    ul {
        list-style: none;
        display: flex;
        padding: 0;
        margin: 0;
        flex-wrap: wrap;

        justify-content: center;

        li:last-child {
            a {
                border-right: 1px solid transparent;
            }
        }

        li {
            width: calc(100%/3);
            height: 100%;

            div {
                padding: 5px;
            }

            a:hover {
                background-color:#D64B8B;
                color:white;

                .icon-title {
                    color: white;
                }

                #Path_47, #Path_65{
                    fill: white;
                }
            }

            a {
                height: 100%;
                background-color: #EDEDED;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                padding-top: 20px;
                padding-bottom: 20px;
                border-right: 1px solid white;

                .icon-svg {
                    width: 60px;
                    height: 60px;
                }

                svg {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

div#featured-voiceboxes .item-title h3 a {
    font-weight: 400;
    color: #125553;
}

@media only screen and (max-width: 340px) {
    #category-links {
        //padding: 0 40px;

        .icon-title {
            font-size: em(16);
        }

        ul {
            li {
                a {
                    .icon-svg {
                        width: 50px;
                        height: 50px;
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 640px) {
    #category-links {
        padding: 0 40px;

        .icon-title {
            font-size: em(30);
        }

        ul {
            li {

                div {
                    padding: 5px;
                }

                a {
                    .icon-svg {
                        width: 80px;
                        height: 80px;
                    }
                    padding-top: 30px;
                    padding-bottom: 30px;
                }
            }
        }
    }
}

@media only screen and (min-width: 900px) {
    #category-links {
        padding: 0 40px;

        .icon-title {
            font-size: em(30);
        }

        ul {
            li {
                width: 20%;

                div {
                    padding: 0;
                }

                a {
                    .icon-svg {
                        width: 80px;
                        height: 80px;
                    }
                }
            }
        }
    }
}

#features {
    @include span-columns(12);
    background: $white;

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        @include span-columns(12);
    }

    li {
        @include span-columns(12);
        position: relative;
    }

    .type-content {
        a {
            display: block;
            border-bottom: 1px solid $white;

            &:hover .post-img img {
                opacity: 1;
            }
        }
    }
}

#showtime {
    @include span-columns(12);
    padding-top: 15px;
    @include bg('main/optimized/bg-gradientdrop.webp', left, top, repeat-x);
    background-color: $white;
}

#featured-video,
#from-community,
#featured-voiceboxes,
#featured-user,
#space-homepage,
#newsletter-homepage,
#tickets-homepage,
#feature-boxes {
    @include span-columns(12);
    background: $white;
}

#from-community,
#featured-voiceboxes,
#featured-user,
#space-homepage,
#feature-boxes {
    border-top: $border;
}

#from-community {
    .item-img {
        overflow: hidden;
        position: relative;

        .item-type {
            z-index: 2;
        }

        a {
            position: relative;
            z-index: 1;
        }

        img {
            width: 100%;
        }
    }

    .item-type {
        position: absolute;
        top: 10px;
        z-index: 9;
        font-size: em(12);
        line-height: 1;
        text-transform: uppercase;
        padding: 2px 10px 2px 20px;
        left: 0px;
        background: $primarycolor;
        color: $white;
    }

    .block-item {
        padding-bottom: 40px;
    }

    .block-inner {
        .block-title {
            h2 {
                color: $primarycolor;
            }

            .icon-svg {
                display: inline-block;
                vertical-align: middle;
                width: 36px;
                height: 49px;
                margin-right: 10px;

                svg g {
                    fill: $primarycolor;
                }
            }
        }
    }

    .item-summary {
        margin-top: 10px;

        .post-title {
            margin: 0;
            font-weight: bold;
            font-size: em(20);
            line-height: 1.2;

            a {
                color: $black;
            }
        }

        .post-author, .post-date {
            display: block;
            font-size: em(14);
            color: $lightneutralcolor;
        }

        .post-author {
            margin: 6px 0;

            a {
                color: $lightneutralcolor;
            }
        }

        .post-date {
            margin-bottom: 8px;
        }
    }

    .block-footer {
        font-weight: 400;
        text-transform: uppercase;
        font-size: em(12);
    }
}

#featured-voiceboxes {
    .owl-prev,
    .owl-next,
    .glide__arrow {
        left: 0px;
        background: transparent;
        border-right: 0;

        &.disabled i {
            opacity: .2;
        }

        width: 70px;

        i {
            width: 70px;
        }
    }

    .owl-next,
    .glide__arrow--right {
        left: auto;
        right: 0px;
    }

    .owl-nav,
    .glide__arrows {
        display: none;
    }

    .block-inner {
        padding: 20px;
    }

    .block-item {
        margin-bottom: 35px;
        padding-top: 0;
    }

    .item-title {
        padding: 0 0 20px;

        h3 {
            font-family: $altfontfamily;
            text-transform: uppercase;
            font-size: em(30);
            margin: 0;

            a {
                color: $primarycolor;
            }
        }
    }

    .item-list {
        padding: 0;
        margin: 0 -20px;
        width: auto;

        .voicebox-carousel-item {
            //width: 240px;
        }
    }
}

#space-homepage {
    a {
        display: block;
        padding: 20px;
        margin: 0 auto;
    }

    img {
        width: 100%;
    }

    .space-small {
        display: block;
    }

    .space-medium {
        display: none;
    }
}

#tickets-homepage {
    .block-cta {
        padding-top: 20px;

        a {
            color: $secondarycolor;
        }
    }

    .item-list {
        margin: 0 -20px;
        width: auto;
    }

    .no-js & {
        .glide__slide {
            max-width: 125px;
            margin-right: 6px;
        }
    }

    .glide__slide {
        a {
            position: relative;
            display: block;

            img {
                display: block;
                width: 100%;
            }
        }

        .show-price {
            display: block;
            position: absolute;
            top: 10px;
            left: 0px;
            background: $primarycolor;
            color: $white;
            font-size: em(12);
            line-height: 1;
            text-transform: uppercase;
            padding: 2px 10px;
            white-space: nowrap;
        }
    }
}

#feature-boxes {
    padding: 0;

    .post-img,
    .post-img img {
        max-width: none;
    }

    .item-summary,
    .item-cta {
        margin: 0;
    }

    .post-cont {
        padding-top: 20px;
    }

    .post-info {
        padding: 10px 20px 20px;
    }
}

#homepage-intro {
    .intro-wrap.intro-noimage {
        .intro-text {
            padding-left: 0;
        }
    }

    h1 {
        font-weight: bold;
        font-size: em(34);
        margin: 0;
    }

    .page-content {
        :first-child {
            margin-top: 0;
        }

        p {
            color: $textcolor;
        }
    }
}

.homepage--careers {
    #homepage-hubs-nav {
        background: $secondarycolor;

        p {
            border-right-color: lighten($secondarycolor, 5%);
        }

        &.nav-sub {
            a {
                &:hover {
                    background: lighten($secondarycolor, 15%);
                }
            }
        }
    }
}


//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> ARTS AWARD                                               #
//                                                                             #
//##############################################################################

#homepage-intro {
    @include span-columns(12);

    .intro-wrap {
        padding: 20px 20px 10px;
    }

    .intro-img {
        display: none;
    }

    h2 {
        font-family: $altfontfamily;
        font-size: em(26);
        line-height: 1;
        color: $aa-primarycolor;
        text-transform: uppercase;
        margin: 0;
    }

    p {
        color: $neutralcolor;
        font-size: em(15);
    }

    .sig-img {
        img {
            width: 100px;
        }
    }

    .sig-role {
        display: block;
        font-size: em(13);
        color: $neutralcolor;
        margin-top: 5px;
    }
}

#homepage-hubs-nav {
    background: #404041;
    font-size: em(14);

    p {
        color: $white;
        margin: 0;
        padding: 20px;
        padding-bottom: 10px;
    }

    &.nav-sub {
        ul {
            margin: 0 0 15px;
            padding: 0 10px;
            text-align: left;
            font-size: em(16, 14);
        }

        li {
            display: list-item;
            margin-bottom: 5px;
        }

        a {

            text-shadow: none;

            .fa {
                margin-right: 8px;
            }

            &:hover {
                background: lighten(#404041, 15%);
            }
        }
    }
}

#homepage-hubs {
    @include span-columns(12);

    .item-wrap {
        @include span-columns(12);
        position: relative;
        border-top: 1px solid white;
    }

    .wrap-inner {
        padding-bottom: 40px;
    }

    .hub-explore {
        background: $aaexplore;
    }

    .hub-bronze {
        background: $aabronze;
    }

    .hub-silver {
        background: $aasilver;
    }

    .hub-gold {
        background: $aa-tertiarycolor;
    }

    .item-title {
        font-family: $altfontfamily;
        line-height: 1;
        padding: 26px 0 0 20px;
        margin: 0;
        color: #fff;
        font-size: em(40);
        text-transform: uppercase;
    }

    .hub-link {
        position: absolute;
        top: 30px;
        right: 20px;
        color: #fff;
        text-transform: uppercase;
        font-weight: bold;

        .fa {
            font-size: em(30);
            vertical-align: middle;
        }

        span {
            font-size: em(16);
            vertical-align: middle;
        }
    }

    .item-info {
        margin: 15px 20px;
        font-size: em(20);
    }

    .more-link {
        position: absolute;
        right: 15px;
        bottom: 15px;
        @include bg('main-v2.1/hub-arrow-fom-chalk.png');
        width: 176px;
        height: 21px;
    }
}

#homepage-aainfo {
    @include span-columns(12);
    background: #404041;

    .aainfo-text {
        @include span-columns(12);
        border-top: 1px solid #fff;
        position: relative;

        .wrap-inner {
            padding-bottom: 40px;
        }

        h2 {
            font-family: $altfontfamily;
            line-height: 1;
            margin: 26px 0 0 20px;
            color: #fff;
            font-size: em(24);
            text-transform: uppercase;
        }

        p {
            color: #fff;
            margin: 0 20px;
            font-size: em(13);
        }

        ul {
            list-style: none;
            margin: 0 20px;
            padding: 0;
        }

        li {
            margin: 0;

            a {
                color: #fff;
                font-size: em(13);
                font-weight: bold;
                line-height: 1;

                .fa {
                    margin-right: 5px;
                    font-size: em(18, 13);
                }
            }
        }

        .more-link {
            position: absolute;
            right: 15px;
            bottom: 15px;
            @include bg('main-v2.1/hub-arrow-chalk.png');
            width: 67px;
            height: 25px;
        }
    }

    .aainfo-video {
        @include span-columns(12);
        border-top: 1px solid #fff;
        position: relative;

        a {
            &:hover img {
                opacity: 1;
            }
        }

        img {
            width: 100%;
            opacity: 0.4;
            transition: opacity .5s;
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
            color: #fff;
            text-align: center;

            b {
                display: block;
                margin-top: 10px;
                font-family: $baseheadingfont;
                text-shadow: 1px 1px 5px $black;
                font-size: em(16);
            }
        }

        .fa {
            font-size: em(50);
        }
    }
}

#homepage-aaposts {
    @include span-columns(12);
    background: $white;

    ul {
        @include span-columns(12);
        list-style: none;
        margin: 0;
        padding: 0;
        border-top: 1px solid #fff;
    }

    li {
        @include span-columns(12);
        position: relative;
    }

    a {
        display: block;
        border-bottom: 1px solid $white;

        &:hover .post-img img {
            opacity: 1;
        }
    }

    .post-type {
        background: $aa-secondarycolor;
        color: $white;
    }
}

#homepage-users {
    @include span-columns(12);
    background: $white;

    ul {
        @include span-columns(12);
        list-style: none;
        margin: 0;
        padding: 0;
    }

    li {
        @include span-columns(12);
        position: relative;
    }

    a {
        display: block;
        border-bottom: 1px solid $white;

        &:hover .post-img img {
            opacity: 1;
        }
    }

    .post-info {
        position: absolute;
        top: 30px;
        left: 0px;
        right: 20px;
    }

    .post-type,
    .post-title {
        position: static;
    }

    .post-title {
        padding: 15px 20px 0;
        clear: both;
        line-height: 1;
    }

    .post-title-sub {
        font-size: 0.7em;
    }

    .post-type {
        display: block;
        background: $aa-secondarycolor;
        color: $white;
        padding: 2px 10px 2px 20px;
        float: left;
        max-width: 75%;
    }
}

#homepage-aacarousel {
    @include span-columns(12);

    .block-voiceboxes,
    .block-item {
        padding: 0;
    }
}

#homepage-spacer {
    @include span-columns(12);
    padding-bottom: 15px;
    @include bg('main/optimized/bg-gradientdrop.webp', left, top, repeat-x);
}

.homepage-title {
    float: left;
    display: block;
    width: 100%;
    background-image: linear-gradient(white 50%, lightgray 100%);
    h2 {
        font-family: $altfontfamily;
        font-size: em(26);
        line-height: 1;
        color: $aa-primarycolor;
        text-transform: uppercase;
        margin: 0;
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-weight: bold;
    }
}

//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> Trinity                                             #
//                                                                             #
//##############################################################################

.templateTR {
    #showtime {
        background-image: none;
        padding-top: 0;
    }

    #homepage-intro {
        border-top: 1px solid #a7a9ac;

        .intro-wrap {
            padding-bottom: 0;

            p {
                margin-bottom: 0;
            }
        }
    }

    #feature-boxes {
        border-top: none;

        .post-title {
            position: absolute;
            top: 0px;
            margin: 0;
            line-height: 1;
            padding: 26px 0 0 20px;
            margin: 0;
            font-size: em(26); //26
            line-height: 1.2;
            font-weight: bold;
            color: $white;
            text-shadow: 1px 1px 5px $black;
        }
    }
}

#homepage-trqualifications {
    @include span-columns(12);
    background: $white;

    ul {
        @include span-columns(12);
        list-style: none;
        margin: 0;
        padding: 0;
        border-top: 1px solid #fff;
    }

    li {
        @include span-columns(12);
        position: relative;
    }

    a {
        display: block;
        border-bottom: 1px solid $white;

        &:hover .post-img img {
            opacity: 1;
        }
    }

    .post-type {
        background: $aa-secondarycolor;
        color: $white;
    }

    .post-title {
        margin: 0;
        top: 16px; //60
        left: 20px;
        right: 20px;
        height: 74px; //97
        overflow: hidden;
        font-size: em(26); //16
        line-height: 1.2;
        font-weight: bold;
        color: $white;
        text-shadow: 1px 1px 5px $black;
    }

    .post-link_text {
        bottom: 15px;
        background-color: transparent;
        color: white;
        overflow: hidden;
        height: 10px;
        font-size: 0.75em;
        line-height: 1;
        text-transform: uppercase;

        i {
            margin-left: 3px;
        }
    }
}

#homepage-subjects {
    @include span-columns(12);

    .item-wrap {
        @include span-columns(12);
        position: relative;
        border-top: 1px solid white;

        a {
            display: block;
        }
    }

    .wrap-inner {
        padding-bottom: 40px;
    }

    .subject-1 {
        background: $aaexplore;
    }

    .subject-2 {
        background: $aabronze;
    }

    .subject-3 {
        background: $aasilver;
    }

    .subject-gold {
        background: $aa-tertiarycolor;
    }

    .item-title {
        margin: 0;
        line-height: 1;
        padding: 26px 0 0 20px;
        margin: 0;
        font-size: em(36); //26
        line-height: 1.2;
        font-weight: bold;
        color: $white;
        text-shadow: 1px 1px 5px $black;
    }
    .item-info {
        margin: 15px 20px;
        color: white;
    }

    .more-link {
        position: absolute;
        right: 15px;
        bottom: 15px;
        @include bg('main-v2.1/hub-arrow-fom-chalk.png');
        width: 176px;
        height: 21px;
    }
}

#homepage-trqualifications .type-content,
#homepage-subjects .item-wrap {
    margin-top: 20px;
}


#homepage-trqualifications .type-content .content-spacer,
#homepage-subjects .item-wrap .content-spacer{
    margin-left: 5px;
    margin-right: 5px;
}

.templateTR #feature-boxes .i1,
.templateTR #feature-boxes .i3 {
    .content-spacer {
        margin-left: 5px;
    }
}

.templateTR #feature-boxes .i2,
.templateTR #feature-boxes .i4 {
    .content-spacer {
        margin-right: 5px;
    }
}

.templateTR #feature-boxes .content-spacer {
    /*margin-left: 5px;
    margin-right: 5px;*/
}

/*
#homepage-trqualifications .type-content.i1 .content-spacer,
#homepage-subjects .item-wrap.i1 > a {
    padding-left: 0;
}

#homepage-trqualifications .type-content.i4 .content-spacer,
#homepage-subjects .item-wrap.i3 > a {
    padding-right: 0;
}*/

.homepage--media {

    .content-area {
        .post-summary {
            margin-bottom: 40px;
        }
    }
    .page-wrap {
        max-width: 1100px;
        margin: 0 auto;
    }

    .search-wrap {
        form {
            .search-box {
                position: relative;
                border: 2px solid #878787;
                .icon {
                    position: absolute;
                    display:flex;
                    height: 100%;
                    align-items: center;
                    justify-content: center;
                    font-size: 27px;
                    color:#878787;
                    width: 80px;
                }
                input {
                    border: 0;
                    border-radius: 0;
                    padding: 15px 80px 15px;
                }
            }
        }
    }

    #homepage-intro {
        .intro-text {
            h2, p {
                color: black;
            }
        }
    }

    .split-post {
        max-width: 640px;
        width: 100%;
        margin-top: 20px;
        float: left;
        position: relative;
        .post-img {
            margin-top: 0;
            img {
                width: 100%;
            }
        }
        .post1-caption {
            top: 20px;
            left: 40px;
            position: absolute;
            width: 50%;
        }
        .post-title {
            margin: 0;
            overflow: hidden;
            font-size: 1.75em;
            line-height: 1.2;
            font-weight: bold;
            color: white;
            text-shadow: 1px 1px 5px black;
        }
        .post-date {
            margin-top: 20px;
            text-transform: uppercase;
            color: white;
            display: block;
        }
        .post2-overlay {
            position: absolute;
            bottom: 0;
            right: 0;
            background-color: #874B8B;
            width: 100%;
            height: 100%;
            clip-path: polygon(100% 100%, 0% 100%, 100% 0);
            a {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        .post2-caption {
            bottom: 20px;
            right: 40px;
            position: absolute;
            width: 50%;
            text-align: right;
        }

        .text-truncate {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            line-height: 34px;     /* fallback */
            max-height: 68px;      /* fallback */
            -webkit-line-clamp: 2; /* number of lines to show */
            -webkit-box-orient: vertical;
        }

    }

    .splide__slide .post-title {
        margin: 0;
        overflow: hidden;
        font-size: 1.75em;
        line-height: 1.2;
        font-weight: bold;
        color: white;
        text-shadow: 1px 1px 5px black;
        margin-left: 20px;
        margin-right: 20px;
    }

    .splide {
        width: 100%;
        .viewall {
            a {
                width: 100%;
                background:#D64B8B;
                &:hover {
                    background-color: #df75a6;
                }
                &:hover img {
                    filter: alpha(opacity=60);
                    opacity: 0.3;
                }
            }
        }
        a {
            background:#D64B8B;
            display: block;
            &:hover img {
                filter: alpha(opacity=100);
                opacity: 1;
            }
        }
        img {
            width: 100%;
            object-fit: cover;
            height: 250px;
            filter: alpha(opacity=60);
            opacity: 0.6;
            transition: all 0.4s;
        }
        .viewall {
            width:100%;
            /*height:250px;
            background:#D64B8B;*/
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
        }
    }

    .splide__arrows--ttb .splide__arrow--prev {
        top: 1em;
        left: 50%;
    }
    .slide-color-overlay {
        position: absolute;
        background-color: #D64B8B;
        opacity: 0.5;
        width: 100%;
        height: 100%;
    }
        .slide-text-overlay {
            position: absolute;
            opacity: 1;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 0;
            top: 0;
            /*&:hover {
                filter: alpha(opacity=60);
                opacity: 0.6;
            }*/
        }
        .sidebar-area {
            float:left;
            width:100%;

            .block-inner {
                padding-right: 0;
                padding-top: 0;
                padding-left: 0;
            }

            .block-item:first-child {
                .item-title {
                        h3 {
                            margin-top: 0;
                    }
                }
            }

            .block-item {
                float: right;
                width:100%;



                .item-title {
                    h3 {
                        line-height: 1;
                        font-size: 35px;
                        font-weight: bold;
                        margin-top: 60px;
                        margin-bottom: 40px;
                    }
                }
            }
        }

    #media-posts-feed {
        width: 100%;
        max-width: 640px;
        hr {
            margin: 40px 0 0 0;
            border-color: #A078A8;
        }

        .content-list.listing-full-info {
            padding: 0;
            .post-title a {
                font-size: 28px;
            }
            .post-date, .item-summary {
                font-size: 18px;
            }
            .post-info {
                margin-top: 20px;
            }
            .post-img {
                max-width: 100%;
                width: 100%;
                margin-top: 0;
                img {
                    max-width: 100%;
                    width: 100%;
                }
            }
        }

        .media-posts-filter {
            display: flex;
            flex-direction: column;
            margin-top: 40px;
            margin-bottom: 40px;
            a {
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    margin-right: 5px;
                }

                span {
                    margin-left: 5px;
                }
            }
        }

        a.viewmore {
            margin: 0 0px 10px 0px;
            display: block;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 16px;
        }

        a.video-filter {
            margin-bottom: 40px;
            background:#544580;

            &:hover {
                background: lighten(#544580, 10%);
            }
        }

        a.viewmore, a.audio-filter {
            background: $primarycolor;

            &:hover {
                background: lighten($primarycolor, 10%);
            }
        }

        a.viewmore, a.video-filter, a.audio-filter {
            color: $white;
            font-size: em(18);
            font-family: $baseheadingfont;
            padding: 10px 20px;
            text-align: center;

            .fa {
                margin-right: 10px;
            }
        }
    }

    #feature-boxes {
        border-top: 0;
    }
}

#homepage-header {
    @include span-columns(12);

    .header-wrap {
        padding: 20px 20px 10px;
    }

    .header-img {
        display: none;
    }

    p {
        margin-top: 0;
    }

    .page-content p:first-child,
    .fr-view img.fr-dib {
        margin-top: 0;
        margin-bottom: 0;
    }
}

.fr-view img.fr-dii {
    max-width: 100%;
}

//##############################################################################
//                                                                             #
//	! 6.0 LISTINGS PAGE                                            #
//                                                                             #
//##############################################################################

.listing-filters > div {
    width: 100%;
    margin-left: -10px;

    .selectric {
        background-color: white;
        border: transparent;
        font-family: $altfontfamily;

        .label {
            font-size: 24px;
            color: #D64B8B;
        }

        .button-arrow {
            display: block;
            position: absolute;
            right: 0;
            top: 0;
            width: 38px;
            height: 38px;
            line-height: 38px;
            background-color: white;
            color: #BBB;
            text-align: center;
            font: 0 / 0 a;
        }
    }

    .selectric-items li {
        font-size: 15px;
        font-family: $baseheadingfont;
        background-color: white;
    }

    .selectric-items {

        li:hover, li.selected:hover {
            background-color: #D64B8B;
            color: white;
        }
        li.selected {
            background-color: white;
        }
    }

    .filter-buttons {
        font-size: 24px;
        font-family: $altfontfamily;
        color: #D64B8B;
        background-color: #F0F0F0;
        border: none;
        color: #D64B8B;
        padding: 8px 16px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        margin: 4px 2px;
        cursor: pointer;
        border-radius: 50px;

        width: 50%;
    }

    .filter-controls {
        margin-left: auto;
        display: flex;
    }

    #toggle-filters {
        margin-right: 10px;
    }

    #reset-filters {
        margin-left: 10px;
    }

    .hide-filters {
        display: none;
    }

    @media only screen and (min-width: 900px) {
        display: flex;


        .hide-filters {
            display: block;
        }

        .filters-wrapper {
            display: flex;
        }

        .row {
            margin-right: 20px;
            min-width: 100px;
            margin-bottom: 0;
            display: flex;
            align-items: center;
        }

        .filter-buttons {
            width: auto;
        }

        .filter-controls {
            display: block;

        }

        #toggle-filters {
            margin-right: 0;
            display: none;
        }

        #reset-filters {
            margin-left: 0;
        }
    }
}

.sr-only {
    position:absolute;
    left:-10000px;
    top:auto;
    width:1px;
    height:1px;
    overflow:hidden;
}

/* ==|== media queries ======================================================
   Media Queries for Responsive Design.
   ========================================================================== */

@media only screen and (min-width: 480px) {
    @import "480";
}

@media only screen and (min-width: 640px) {
    @import "640";
}

@media only screen and (min-width: 768px) {
    @import "768";
}

@media only screen and (min-width: 900px) {
    @import "900";
}

@media only screen and (min-width: 1000px) {
    .menu__bubbles {
        .bubble__text {
            .bubble__title {
                font-size: 16px;
            }
        }

        .bubbles__spacer {
            width: 60px;

            .spacer__bubble {
                display: inline;
            }

            .spacer__placeholder {
                width: 60px;
            }
        }
    }
}

@media only screen and (min-width: 1100px) {
    @import "nav";
    @import "1100";
}

@media only screen and (min-width: 1200px) {
    @import "1200";
}

/*@media only screen and (min-width: 2000px)
{

}*/


@media print,
(min-resolution: 1.25dppx),
(min-resolution: 120dpi) {
    .backgroundsize {
        .homepage #header .header-tags {
            @include bg('main-v2.1/header-tags_2x.png');
            background-size: 346px 143px;
        }

        #homepage-hubs {
            .more-link {
                @include bg('main-v2.1/hub-arrow-fom-chalk_2x.png');
                background-size: 176px 21px;
            }
        }

        #homepage-aainfo {
            .more-link {
                @include bg('main-v2.1/hub-arrow-chalk_2x.png');
                background-size: 67px 25px;
            }
        }

        .post-content blockquote,
        .page-content blockquote,
        .post-summary blockquote,
        .post-wrap .post-quote {
            @include bg('main/icon-blockquote_2x.png', 15px, 30px);
            background-size: 33px 28px;
        }
    }
}

@media print {
    @import "print";
}

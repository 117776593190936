//##############################################################################
//                                                                             #
//	! 1.1 GLOBAL -> HELPERS/EXTRAS                                             #
//                                                                             #
//##############################################################################

.border-col {
    position: relative;

    &:after {
        content: "";
        position: absolute;
        z-index: 20;
        top: 0px;
        bottom: 0px;
        right: 0px;
        width: 1px;
        background: $bordercolor;
    }
}

#showtime {
    .border-col:after {
        content: none;
    }
}


//##############################################################################
//                                                                             #
//	! 1.2 GLOBAL -> HEADER                                                     #
//                                                                             #
//##############################################################################

#header {
    #logo-full {
        display: block;
        position: absolute;
        z-index: 999;
        top: 30px;
        //width: (1174px / 3);
        //height: (320px / 3);
        left: 50%;
        //margin-left: -((1174px / 3) / 2);

        width: 50%;
        margin-left: -25%;

        img {
            width: 100%;
        }

        display: none;
    }

    .header-title {
        font-size: em(48);
    }
}


//##############################################################################
//                                                                             #
//	! 1.3 GLOBAL -> FOOTER                                                     #
//                                                                             #
//##############################################################################

#footer {
    .footer-sitemap {
        @include span-columns(12);
    }

    .site-list {
        @include span-columns(6);

        ul {
            float: left;
            margin-right: 60px;
        }
    }

    .footer-support-links {
        .support,
        .friend {
            @include span-columns(6);
        }
    }
}


//##############################################################################
//                                                                             #
//	! 3 CONTENT -> ASIDE                                                       #
//                                                                             #
//##############################################################################

.block-aside {
    //@include span-columns(6);
    border-top: none;
}

.sidebar-col-1 {
    @include span-columns(12);
    border-top: $border;
}

.sidebar-col-2 {
    @include span-columns(12);
    border-top: $border;

    &:after {
        right: 50%;
    }

    .block-aside {
        @include span-columns(6);
    }
}


//##############################################################################
//                                                                             #
//	! 3 CONTENT -> BODY                                                        #
//                                                                             #
//##############################################################################

.post-wrap {
    .post-footer {
        display: table;

        ul {
            display: table-cell;
        }
    }

    .post-notice {
        display: table-caption;
        position: relative;
        padding-left: 64px;

        img {
            position: absolute;
            top: 15px;
            left: 20px;
            width: 24px;
        }

        p {
            margin: 0;
            font-size: em(13);
        }
    }

    .post-cta {
        text-align: right;

        a,
        button,
        .fake-btn {
            .fa {
                float: none;
                font-size: em(45, 24);
                margin-left: 20px;
                margin-right: 0;
            }
        }
    }
}


//##############################################################################
//                                                                             #
//	! 3 CONTENT -> HUB                                                         #
//                                                                             #
//##############################################################################

.hub-intro {
    .hub-intro-links {
        display: table;
        width: 100%;

        .hub-intro-link {
            display: table-cell;
            width: 33.33%;
            padding-right: 10px;
            padding-left: 10px;

            &.last {
                padding-right: 0px;
                padding-left: 10px;
            }
            &.first {
                padding-left: 0px;
            }
        }
    }
}


//##############################################################################
//                                                                             #
//	! 3 CONTENT -> INTRO                                                       #
//                                                                             #
//##############################################################################

.content-info-side {
    .img {
        width: 48%;
        float: left;
    }

    p {
        background: white;
        width: 49%;
        float: right;
        margin: 0;
        padding: 2% 1% 0 2%;
    }
}

.content-info-full {
    @include span-columns(12);
    position: relative;

    &.with-img:after {
        content: "";
        position: absolute;
        top: 0px;
        bottom: 0px;
        right: 41.6666666667%;
        width: 1px;
        background: $bordercolor;
    }

    .img {
        @include span-columns(5);
    }

    .block-info {
        @include span-columns(7);
    }
}


//##############################################################################
//                                                                             #
//	! 3 CONTENT -> LAYOUT                                                      #
//                                                                             #
//##############################################################################

#content {
    &:after {
        content: none;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> ARTFORM ICONS                                             #
//                                                                             #
//##############################################################################

#menu-artform {
    li {
        width: 33.333%;
        border-right: 1px solid $lightestneutralcolor;

        &:nth-child(even) {
            border-right: 1px solid $lightestneutralcolor;
        }

        &:nth-child(3n+1) {
            border-right: 0;
        }

        &.i3,
        &.i4 {
            border-top: 0;
        }
    }

    .explore {
        width: 100%;
        height: auto;
        padding: 0 0 10px;
        text-align: left;

        b {
            width: 100%;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> BLOCKS                                                    #
//                                                                             #
//##############################################################################

.block-authormore {
    &.post-count-3 {
        .t3 {
            display: none;
        }

        .more-posts {
            display: inline-block;
        }
    }
}

.block-profile {
    .profile-meta {
        li {
            margin: 0 0 8px 0;
        }

        b {
            display: inline;
            float: left;
            width: 140px;
        }

        span {
            float: left;
        }
    }

    .profile-logos {
        img {
            display: inline-block;
            margin-bottom: 0;
            margin-right: 15px;
        }
    }

    .profile-counts {
        margin-bottom: 0;

        li {
            display: inline;
            margin: 0 0 0 60px;
        }
    }
}

.block-donate{
    .supportdonate{
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &__text{
            p{
                text-align: left;
            }
        }

        &__btn{
            justify-content: center;

            .btn{
                padding-top: 2px;
                padding-bottom: 2px;
                margin: 0;
            }

            .other{
                position: absolute;
                bottom: -25px;
                font-size: em(13);
                padding: 0;
                margin: 0;
            }
        }
    }
}

.block-map {
    .map-area,
    .map-address {
        float: left;
        width: 50%;
    }

    .map-area {
        padding-top: 50%;
    }
}

.block-notice {
    p {
        font-size: em(13);
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> BREADCRUMB/BUTTONS                                        #
//                                                                             #
//##############################################################################

#title-bar {
    @include span-columns(12);
    border-top: $border;

    .breadcrumbs {
        float: left;
        padding-right: 0;
    }

    .buttons {
        float: right;
        padding-top: 12px;
        padding-right: 10px;

        button,
        .fake-btn {
            font-size: em(12);

            .fa {
                font-size: em(24, 12);
            }
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> FORM                                                      #
//                                                                             #
//##############################################################################

.form {
    .row-2col {
        .row-checkbox {
            display: inline-block;
            width: 44%;
            margin-right: 5%;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> FORM -> COMMENT                                           #
//                                                                             #
//##############################################################################

.block-comment-form {
    .form-comment {
        display: table;
    }

    .image-profile {
        display: table-cell;
    }

    .comment-field {
        display: table-cell;
        padding-left: 20px;
    }

    .profile-select {
        width: 260px;
        margin: 0;
        position: absolute;
        top: 13px;
        right: 20px;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> LISTING                                                   #
//                                                                             #
//##############################################################################

.listing-image-grid {
    .post-title {
        font-size: em(18);
        height: 64px;
    }

    &.no-footer {
        .post-title {
            height: 107px;
        }
    }

    .theme-wrap {
        border-right: 1px solid $white;
        border-bottom: 1px solid $white;
        position: absolute;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;

        a {
            #features & {
                position: static;

                &:after {
                    content: none;
                }
            }
        }
    }

    .type-theme {
        position: relative;
        padding-bottom: 0;

        &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: (500 / 832) * 100%;
        }

        p {
            font-size: em(12);
        }

        a {
            font-size: em(14);

            &:after {
                content: none;
            }
        }
    }
}

.content-list.listing-image-grid {
    li {
        @include span-columns(6);
    }

    .post-cont {
        margin: 0;

        &:after {
            content: "";
            position: absolute;
            top: 0px;
            bottom: 0px;
            right: 0px;
            width: 1px;
            background: $white;
        }
    }

    .i2 {
        .post-cont {
            &:after {
                content: none;
            }
        }
    }
}

.content-list.listing-full-info {
    /*padding-left: 0px;*/

    li {
        position: relative;
        padding-left: 228px;
        min-height: 160px;
    }

    .post-img {
        width: 208px;
        position: absolute;
        top: 0px;
        left: 0px;

        img {
            position: static;
            margin-top: 0;
        }
    }

    .post-info {
        padding-top: 0;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> SLIDESHOW                                                 #
//                                                                             #
//##############################################################################

.slideshow-carousel {
    .item {
        .item-img {
            .custom-next,
            .custom-prev {
                display: block;
            }
        }
    }
}


//##############################################################################
//                                                                             #
//	! 5.1 HOMEPAGE -> VOICE                                                    #
//                                                                             #
//##############################################################################

#features {
    li {
        @include span-columns(6);
    }

    .i2 a,
    .i4 a,
    .i6 a {
        &:after {
            content: none;
        }
    }

    a {
        position: relative;
        z-index: 1;

        &:after {
            content: "";
            position: absolute;
            z-index: 99;
            top: 0px;
            bottom: 0px;
            right: 0px;
            width: 1px;
            background: $white;
        }
    }
}

.item-feature{
    .item-thumb{
        a{
            .fa{
                font-size: em(62);
            }
        }
    }
}

#from-community{
    .block-item{
        display: flex;
    }

    .item-img{
        flex: 0 0 208px;
    }

    .item-summary{
        padding-left: 20px;
        margin-top: 0;
    }

    .block-footer{
        margin-top: -20px;
    }
}

#featured-voiceboxes{
    .item-list {
        .voicebox-carousel-item{
            //width: 313px;
        }
    }
}

#newsletter-homepage{
    .form{
        .row{
            width: 340px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

#feature-boxes{
    display: flex;
    flex-wrap: wrap;

    &:before{
        content: none;
    }

    .post-cont{
        flex: 0 0 50%;

        &:nth-child(odd){
            .post-img{
                &:before{
                    content: '';
                    position: absolute;
                    top: 0px;
                    right: 0px;
                    bottom: 0px;
                    width: 1px;
                    background: #fff;
                }
            }
        }
    }

    .post-img{
        position: relative;
        width: auto;
    }
}


//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> ARTS AWARD                                               #
//                                                                             #
//##############################################################################

#homepage-hubs-nav {
    font-size: em(16);

    &.nav-sub {
        ul {
            font-size: em(16);
        }

        li {
            display: inline-block;
            margin-left: 15px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

#homepage-hubs {

    .item-wrap {
        @include span-columns(6);

    }

    .wrap-inner {
        border-right: 1px solid #fff;
        height: 220px;
    }

    .hub-bronze,
    .hub-gold {
        .wrap-inner {
            border-right: none;
        }
    }
}

#homepage-aaposts {
    li {
        @include span-columns(6);
    }

    .i2 a,
    .i4 a {
        &:after {
            content: none;
        }
    }

    a {
        position: relative;
        z-index: 1;

        &:after {
            content: "";
            position: absolute;
            z-index: 99;
            top: 0px;
            bottom: 0px;
            right: 0px;
            width: 1px;
            background: $white;
        }
    }
}

#homepage-users {
    li {
        @include span-columns(6);
    }

    .i1 {
        @include span-columns(12);
    }

    .i1 a,
    .i3 a {
        &:after {
            content: none;
        }
    }

    a {
        position: relative;
        z-index: 1;

        &:after {
            content: "";
            position: absolute;
            z-index: 99;
            top: 0px;
            bottom: 0px;
            right: 0px;
            width: 1px;
            background: $white;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> ARTS AWARD                                               #
//                                                                             #
//##############################################################################

#homepage-hubs-nav {
    font-size: em(16);

    &.nav-sub {
        ul {
            font-size: em(16);
        }

        li {
            display: inline-block;
            margin-left: 15px;

            &:first-child {
                margin-left: 0;
            }
        }
    }
}

//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> TRINITY                                               #
//                                                                             #
//##############################################################################

.templateTR {
    #feature-boxes {
        .post-title {
            font-size: em(26);
        }
    }
}

#homepage-trqualifications {
    li {
        @include span-columns(6);
    }

    .i2 a,
    .i4 a {
        &:after {
            content: none;
        }
    }

    .post-title {
        font-size: em(26); //16
        height: 94px;
    }

    /*a {
        position: relative;
        z-index: 1;

        &:after {
            content: "";
            position: absolute;
            z-index: 99;
            top: 0px;
            bottom: 0px;
            right: 0px;
            width: 5px;
            background: $white;
        }
    }*/
}


#homepage-subjects {

    .item-wrap {
        @include span-columns(12);

    }

    .item-info {
        position: relative;
        margin-top: 20px;
        bottom: inherit;
    }

    .wrap-inner {
        /*border-right: 1px solid #fff;*/
        height: 220px;
    }

    .subject-bronze,
    .subject-gold {
        .wrap-inner {
            border-right: none;
        }
    }
}

//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> MEDIA                                                    #
//                                                                             #
//##############################################################################

.homepage--media {
    .content-list.listing-full-info {
        li {
            padding-left: 0;
        }
        .post-img {
            position: relative;
        }
    }
}

#sb-site{
	transform: none;
	transition: none;
	@include bg('main/optimized/bg-noise-light.webp', 0, 0, repeat);
}

.site-container{
	margin-top: 115px;
	transition: margin-top .4s ease-in-out;

	.templateAA & {
		//margin-top: 60px;
	}
}

.hasCookieMsg{
	.site-container{
		margin-top: 147px;
	}

	&.templateAA .site-container{
		//margin-top: 92px;
	}
}

#nav-container{
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	z-index: 100;

	//http://wicky.nillia.ms/headroom.js/
	//https://github.com/WickyNilliams/headroom.js
	&.headroom{
		transition: transform 200ms linear;
	}

	&.headroom--pinned{
		transform: translateY(0%);
	}

	&.headroom--unpinned{
		transform: translateY(-60px);

		.hasCookieMsg & {
			transform: translateY(-92px);
		}
	}

	.container{
		box-shadow: none;
	}
}

#nav-bar{
	height: 60px;
	margin: 0;
	box-shadow: 0 5px 5px rgba($black, 0.25);
	background: $white;

	.headroom--unpinned &{
		box-shadow: none;
	}
}

#nav-sub{
	z-index: 90;
}

.headroom--not-top #nav-sub{

	ul{
		margin-top: 6px;
	}

	li{
		margin-bottom: 6px;
	}

	a{
		font-size: em(16);
		padding: 4px 10px;
	}
}

.lt-ie9{
	#nav-container{
		&.headroom--pinned{
			display: block;
		}

		&.headroom--unpinned{
			display: none;
		}
	}
}

#logo-icon{
	float: left;
	height: 40px;
}

.templateAA{
	#logo-icon{
		height: 40px;
	}
}

.nav-toggle{
	display: none;
}

#nav,
.nav-funcs{
	display: block;
}

$selectize-font-size: em(15);
$selectize-line-height: normal;

$selectize-color-highlight: tint($primarycolor, 70%);

$selectize-color-border: $lightestneutralcolor;
$selectize-color-dropdown-border: $primarycolor;
$selectize-color-dropdown-border-top: $lightestneutralcolor;

$selectize-color-dropdown-item-active: tint($lightestneutralcolor, 70%);

$selectize-color-text: $textcolor;
$selectize-color-dropdown-item-active-text: $selectize-color-text;

$selectize-color-item: $primarycolor;
$selectize-color-item-text: $white;


$selectize-shadow-input: none;
$selectize-shadow-input-focus: none;

$selectize-arrow-color: #a7a9aa;
$selectize-arrow-offset: 10px;


@import "selectize";

.selectize-input {
  &.focus {
    border-color: $primarycolor;
  }

  .selectize-control.multi & > div {
    border-radius: $selectize-border-radius;
  }
}

.selectize-control.plugin-remove_button {
  [data-value] .remove {
    padding-top: 0;
    color: #E0A8C4;
    border-left: none;
    font-weight: normal;
    font-size: 22px;
    line-height: 1;
  }
}

.profile {
  padding: 4px;

  .img {
    vertical-align: middle;
    display: inline-block;
    margin-right: 8px;

    img {
      width: 32px;
      height: 32px;
    }
  }

  .text{
    vertical-align: middle;
    display: inline-block;

    .type{
      display: block;
      font-size: 10px;
      color: $lighterneutralcolor;
      text-transform: uppercase;
    }

    .name{
      font-size: 14px;
    }
  }
}

.selectize-profiles{
  .selectize-input{
    min-height: 40px;
    padding: 0 4px;

    .profile--item{
        padding-left: 0;
    }

    input{
      height: 40px;
      padding: 4px 0 !important;
    }
  }
}
#mc_embed_signup{
	
	.form .submit{
		width: auto;
	}
	
	.form{
		div.mce_inline_error{
			margin: 0;
			padding: 0;
			background: none;
			font-style: italic;
			color: $secondarycolor;
			font-size: em(14);
			font-weight: bold;
		}
		
		input.mce_inline_error{
			border-color: $lightestneutralcolor;
			
			&:focus{
				border-color: $primarycolor;
			}
		}
	}
	
	#mce-error-response,
	#mce-success-response{
		background: $alertcolor;
		color: $white;
		font-weight: bold;
		font-size: em(16);
		margin: 0 0 10px 0;
		padding: 10px;
	}
	
	#mce-success-response{
		background: $successcolor;
	}
	
	.response{
		a{
			color: $white;
			text-decoration: underline;
		}
	}
}
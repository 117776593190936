/*!
 * froala_editor v2.7.0 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2017 Froala Labs
 */

.hide-by-clipping {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}


// !Blockquotes

// blockquote styles are in the main stylesheet


// !Images

.fr-view img {
  position: relative;
  max-width: 100%;
}

// break-text
.fr-view img.fr-dib {
  margin: 20px auto;
  display: block;
  float: none;
  vertical-align: top;
}

.fr-view img.fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}

.fr-view img.fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}

// inline
.fr-view img.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 50%;
}

.fr-view img.fr-dii.fr-fil {
  float: left;
  margin: 5px 10px;
  margin-left: 0;
}

.fr-view img.fr-dii.fr-fir {
  float: right;
  margin: 5px 10px;
  margin-right: 0;
}

// captions
.fr-view span.fr-img-caption {
  position: relative;
  max-width: 100%;
}

.fr-view span.fr-img-caption.fr-dib {
  margin: 20px auto;
  display: block;
  float: none;
  vertical-align: top;
}

.fr-view span.fr-img-caption.fr-dib.fr-fil {
  margin-left: 0;
  text-align: left;
}

.fr-view span.fr-img-caption.fr-dib.fr-fir {
  margin-right: 0;
  text-align: right;
}

.fr-view span.fr-img-caption.fr-dii {
  display: inline-block;
  float: none;
  vertical-align: bottom;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 50%;
}

.fr-view span.fr-img-caption.fr-dii.fr-fil {
  float: left;
  margin: 5px 10px;
  margin-left: 0;
}

.fr-view span.fr-img-caption.fr-dii.fr-fir {
  float: right;
  margin: 5px 10px;
  margin-right: 0;
}

.fr-view .fr-img-caption {
  text-align: center;
}

.fr-view .fr-img-caption .fr-img-wrap {
  padding: 0px;
  display: inline-block;
  margin: auto;
  text-align: center;
  max-width: 100%;
}

.fr-view .fr-img-caption .fr-img-wrap img {
  display: block;
  margin: auto;
}

.fr-view .fr-img-caption .fr-img-wrap > span {
  margin: auto;
  display: inline-block;
  padding: 5px 5px 10px;
  font-size: em(13);
  font-weight: normal;
  color: $lightneutralcolor;
  font-style: italic;
  max-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-opacity: 0.9;
  -moz-opacity: 0.9;
  opacity: 0.9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}


// !Videos

// no styling for videos because we use fitvids to make all videos 100% wide
// all videos are display block and cannot be aligned


// !Embed.ly

.fr-embedly{
	margin: 20px 0;
	height: auto !important;
}



// !Custom

.fr-view{
	
	img.frc-img-va-m{
		vertical-align: middle;
	}
	
	
	a.frc-link-button,
	a.frc-link-button-aa{
		display: inline-block;
		background: $primarycolor;
		color: $white;
		font-family: $baseheadingfont;
		font-weight: bold;
		font-size: em(15);
		padding: 6px 50px;
		margin: 10px 0 0 0;
		text-transform: uppercase;
		text-align: center;
		
		&:hover{
			background: darken($primarycolor, 15%);
			text-decoration: none;
		}
		
		&:focus{
			background-color: $primarycolor;
			text-decoration: none;
		}
		
		.fa{
			margin-left: 10px;
		}
	}
	
	a.frc-link-button-aa{
		background: $aa-primarycolor;
		
		&:hover{
			background: darken($aa-primarycolor, 15%);
		}
		
		&:focus{
			background-color: $aa-primarycolor;
		}
	}
	
	%shared{
		box-sizing: border-box;
		@extend %clearfix;
	}
	
	%clearfix{
		&:after{
			content: "";
			display: table;
			clear: both;
		}
	}
	
	// .frc-cont(ainer)-{window size}-{container size}
	
	div.frc-cont-sm-sm,
	div.frc-cont-md-sm,
	div.frc-cont-lg-sm,
	div.frc-cont-sm-md,
	div.frc-cont-md-md,
	div.frc-cont-lg-md,
	div.frc-cont-sm-lg,
	div.frc-cont-md-lg,
	div.frc-cont-lg-lg{
		margin: 0 auto;
		@extend %shared;
	}
	
	div.frc-cont-sm-sm{
		width: 40%;
	}
	
	div.frc-cont-sm-md{
		width: 60%;
	}
	
	div.frc-cont-sm-lg{
		width: 80%;
	}
	
	
	// .frc-cols-{window size}-{no. columns}
	
	div.frc-cols-sm-2,
	div.frc-cols-sm-3,
	div.frc-cols-sm-4,
	div.frc-cols-md-2,
	div.frc-cols-md-3,
	div.frc-cols-md-4,
	div.frc-cols-lg-2,
	div.frc-cols-lg-3,
	div.frc-cols-lg-4{
		@extend %shared;
		margin-left: -10px;
		margin-right: -10px;
		
		div.frc-col{
			padding: 10px;
		}
	}
	
	div.frc-cols-sm-2{
		div.frc-col{
			float: left;
			width: 50%;
		}
	}
	
	div.frc-cols-sm-3{
		div.frc-col{
			float: left;
			width: 33.33333333%;
		}
	}
	
	div.frc-cols-sm-4{
		div.frc-col{
			float: left;
			width: 25%;
		}
	}
	
	
	// additional rules for .frc-col
	
	div.frc-col{
		@extend %shared;
	}
	
	div.frc-cont-sm-sm,
	div.frc-cont-md-sm,
	div.frc-cont-lg-sm,
	div.frc-cont-sm-md,
	div.frc-cont-md-md,
	div.frc-cont-lg-md,
	div.frc-cont-sm-lg,
	div.frc-cont-md-lg,
	div.frc-cont-lg-lg,
	div.frc-col{
		p{
			margin: 20px 0 0 0;
			
			&:first-child{
				margin-top: 0;
			}
		}
	}
	
	
	// .frc-h(over-)h(ighlight)-{window size}
	
	div.frc-hh{
		padding: 10px;
		
		&:hover{
			background-color: lighten($lightestneutralcolor, 10%);
		}
	}
	
	
	// .frc-b(order-)b(ottom)-{window size}[-alt/-aa]
	
	div.frc-bb-sm,
	div.frc-bb-md,
	div.frc-bb-lg,
	div.frc-bb-sm-alt,
	div.frc-bb-md-alt,
	div.frc-bb-lg-alt,
	div.frc-bb-sm-aa,
	div.frc-bb-md-aa,
	div.frc-bb-lg-aa{
		border-bottom-width: 0px;
		border-bottom-style: solid;
	}
	
	div.frc-bb-sm,
	div.frc-bb-sm-alt,
	div.frc-bb-sm-aa{
		border-bottom-width: 2px;
	}
	
	div.frc-bb-sm{
		border-bottom-color: $primarycolor;
	}
	
	div.frc-bb-sm-alt{
		border-bottom-color: $secondarycolor;
	}
	
	div.frc-bb-sm-aa{
		border-bottom-color: $aa-primarycolor;
	}
	
	
	// .frc-b(order-)r(ight)-{window size}[-alt]
	/*
	div.frc-br-sm,
	div.frc-br-md,
	div.frc-br-lg,
	div.frc-br-sm-alt,
	div.frc-br-md-alt,
	div.frc-br-lg-alt{
		border-right-width: 0px;
		border-right-style: solid;
	}
	
	div.frc-br-sm,
	div.frc-br-sm-alt,
	div.frc-br-sm-aa{
		border-right-width: 2px;
	}
	
	div.frc-br-sm{
		border-right-color: $primarycolor;
	}
	
	div.frc-br-sm-alt{
		border-right-color: $secondarycolor;
	}
	
	div.frc-br-sm-aa{
		border-right-color: $aa-primarycolor;
	}
	*/
	
	
	// .frc-m(argin)b(ottom)-{window size}-{margin size}
	
	div.frc-mb-sm-sm{
		margin-bottom: 40px;
	}
	
	div.frc-mb-sm-md{
		margin-bottom: 60px;
	}
	
	div.frc-mb-sm-lg{
		margin-bottom: 80px;
	}
	
	
	// .frc-f(ont)s(ize)-{window size}-{font size}
	
	.frc-fs-sm-sm{
		font-size: em(14);
	}
	
	.frc-fs-sm-md{
		font-size: em(16);
	}
	
	.frc-fs-sm-lg{
		font-size: em(18);
	}
	
	
	
	@media (min-width: 768px)
	{
		div.frc-cont-md-sm{
			width: 40%;
		}
		
		div.frc-cont-md-md{
			width: 60%;
		}
		
		div.frc-cont-md-lg{
			width: 80%;
		}
	
		div.frc-cols-md-2{
			div.frc-col{
				float: left;
				width: 50%;
			}
		}
		
		
		div.frc-row-md-3{
			div.frc-col{
				float: left;
				width: 33.33333333%;
			}
		}
		
		div.frc-row-md-4{
			div.frc-col{
				float: left;
				width: 25%;
			}
		}
		
		
		div.frc-bb-md{
			border-bottom-color: $primarycolor;
		}
		
		div.frc-bb-md-alt{
			border-bottom-color: $secondarycolor;
		}
		
		div.frc-bb-md-aa{
			border-bottom-color: $aa-primarycolor;
		}
	
		div.frc-bb-sm,
		div.frc-bb-sm-alt,
		div.frc-bb-sm-aa{
			border-bottom-width: 0px;
		}
		
		div.frc-bb-md,
		div.frc-bb-md-alt,
		div.frc-bb-md-aa{
			border-bottom-width: 2px;
		}
		
		/*
		div.frc-br-md{
			border-right-color: $primarycolor;
		}
		
		div.frc-br-md-alt{
			border-right-color: $secondarycolor;
		}
		
		div.frc-br-md-aa{
			border-right-color: $aa-primarycolor;
		}
	
		div.frc-br-sm,
		div.frc-br-sm-alt,
		div.frc-br-sm-aa{
			border-right-width: 0px;
		}
		
		div.frc-br-md,
		div.frc-br-md-alt,
		div.frc-br-md-aa{
			border-right-width: 2px;
		}
		*/
		
		div.frc-mb-md-sm{
			margin-bottom: 40px;
		}
		
		div.frc-mb-md-md{
			margin-bottom: 60px;
		}
		
		div.frc-mb-md-lg{
			margin-bottom: 80px;
		}
		
		
		.frc-fs-md-sm{
			font-size: em(14);
		}
		
		.frc-fs-md-md{
			font-size: em(16);
		}
		
		.frc-fs-md-lg{
			font-size: em(18);
		}
	}
	
	@media (min-width: 1100px)
	{
		div.frc-cont-lg-sm{
			width: 40%;
		}
		
		div.frc-cont-lg-md{
			width: 60%;
		}
		
		div.frc-cont-lg-lg{
			width: 80%;
		}
		
		
		div.frc-cols-lg-2{
			div.frc-col{
				float: left;
				width: 50%;
			}
		}
		
		div.frc-cols-lg-3{
			div.frc-col{
				float: left;
				width: 33.33333333%;
			}
		}
		
		div.frc-cols-lg-4{
			div.frc-col{
				float: left;
				width: 25%;
			}
		}
		
		
		div.frc-bb-lg{
			border-bottom-color: $primarycolor;
		}
		
		div.frc-bb-lg-alt{
			border-bottom-color: $secondarycolor;
		}
		
		div.frc-bb-sm,
		div.frc-bb-sm-alt,
		div.frc-bb-sm-aa,
		div.frc-bb-md,
		div.frc-bb-md-alt,
		div.frc-bb-md-aa{
			border-bottom-width: 0px;
		}
		
		div.frc-bb-lg,
		div.frc-bb-lg-alt,
		div.frc-bb-lg-aa{
			border-bottom-width: 2px;
		}
		
		/*
		div.frc-br-lg{
			border-right-color: $primarycolor;
		}
		
		div.frc-br-lg-alt{
			border-right-color: $secondarycolor;
		}
		
		div.frc-br-lg-aa{
			border-right-color: $aa-primarycolor;
		}
		
		div.frc-br-sm,
		div.frc-br-sm-alt,
		div.frc-br-sm-aa,
		div.frc-br-md,
		div.frc-br-md-alt,
		div.frc-br-md-aa{
			border-right-width: 0px;
		}
		
		div.frc-br-lg,
		div.frc-br-lg-alt,
		div.frc-br-lg-aa{
			border-right-width: 2px;
		}
		*/
		
		div.frc-mb-lg-sm{
			margin-bottom: 40px;
		}
		
		div.frc-mb-lg-md{
			margin-bottom: 60px;
		}
		
		div.frc-mb-lg-lg{
			margin-bottom: 80px;
		}
		
		
		.frc-fs-lg-sm{
			font-size: em(14);
		}
		
		.frc-fs-lg-md{
			font-size: em(16);
		}
		
		.frc-fs-lg-lg{
			font-size: em(18);
		}
	}
}
//##############################################################################
//                                                                             #
//	! 1.2 GLOBAL -> HEADER                                                     #
//                                                                             #
//##############################################################################

#header {
    .banner-image {
        height: 300px;

        img {
            height: 300px;
            margin-left: -625px;
        }
    }

    &.carousel-artsaward {
        .owl-item {
            height: 300px;

            img {
                height: 300px;
                margin-left: -625px;
            }
        }
    }

    #logo-full {
        display: block;
        top: 50px;
        width: (1174px / 2);
        margin-left: -((1174px / 2) / 2);
    }

    .owl-item {
        .item-info {
            h2 {
                font-size: em(26);
            }

            p {
                font-size: em(15);
            }
        }
    }

    .owl-prev,
    .owl-next {
        width: 85px;

        .fa {
            font-size: em(50);
            margin-top: -25px;
        }
    }

    .homepage & {
        .header-tags {
            display: block;
            position: absolute;
            z-index: 21;
            bottom: 25px;
            right: 10%;
            width: 346px;
            height: 143px;
            @include bg('main-v2.1/header-tags.png');
        }
    }
}


//##############################################################################
//                                                                             #
//	! 1.3 GLOBAL -> FOOTER                                                     #
//                                                                             #
//##############################################################################



//##############################################################################
//                                                                             #
//	! 3 CONTENT -> LAYOUT                                                      #
//                                                                             #
//##############################################################################

.sidebar__md {
    .content-info-side,
    .secondary-area,
    #ad-side {
        @include span-columns(3);
        float: right;
    }

    .content-area {
        @include span-columns(9);
    }

    #content:after {
        right: 25%;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> ARTFORM ICONS                                             #
//                                                                             #
//##############################################################################

#menu-artform {
    .explore {
        height: 101px;
        padding-top: (((101 - 57) / 2) - 2 * 1px);

        b {
            font-size: em(28);
            width: 108px;
        }
    }

    .icon {
        .icon-svg {
            width: 50px;
            height: 50px;

            svg {
                width: 50px;
                height: 50px;
            }
        }

        .icon-title {
            font-size: em(16);
            height: 19px;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> BLOCKS                                                    #
//                                                                             #
//##############################################################################

.block-user {
    .item-thumb {
        img {
            width: 140px;
            height: 140px;
        }
    }
}

.block-popular {
    .block-menu {
        a {
            font-size: em(30);
        }
    }
}


.menu__bubbles {
    .bubble__text {
        .bubble__title {
            font-size: 24px;
        }
    }

    .r-tabs-state-active {
        .bubble__text {
            .bubble__title {
                font-size: 30px;
            }
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> LISTING                                                   #
//                                                                             #
//##############################################################################

.listing-image-grid {
    .post-title {
        height: 97px;
        font-size: em(26);
    }

    &.no-footer {
        .post-title {
            height: 158px;
        }
    }
}

.content-list.listing-image-grid {
    &.alt-videos {
        li {
            @include span-columns(3);
        }

        .i2,
        .i3 {
            .post-cont {
                &:after {
                    content: "";
                }
            }
        }

        .i4 {
            .post-cont {
                &:after {
                    content: none;
                }
            }
        }
    }
}




//##############################################################################
//                                                                             #
//	! 5.1 HOMEPAGE -> VOICE                                                    #
//                                                                             #
//##############################################################################

#featured-video{
    flex: 0 0 (flex-grid(8, 12) + flex-gutter(12));
}

#from-community {
    flex: 0 0 (flex-grid(4, 12) + flex-gutter(12));
}

#space-homepage {
    .space-small {
        display: block;
    }

    .space-medium {
        display: none;
    }
}



//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> ARTS AWARD                                               #
//                                                                             #
//##############################################################################

#homepage-hubs-nav {

    p {
        display: inline-block;
        border-right: 2px solid lighten(#404041, 15%);
        padding-top: 10px;
        margin-top: 10px;
        line-height: 1.3;
    }

    &.nav-sub {
        ul {
            display: inline-block;
            margin-bottom: 10px;
        }
    }
}

#homepage-hubs {
    .wrap-inner {
        height: 210px;
    }
}

#homepage-aainfo {
    .aainfo-bubble {
        @include bg('main-v2.1/bubble-chalk-1.png', top, center);
        padding: 20px;
        margin-top: 20px;
    }

    .aainfo-text-2 .aainfo-bubble {
        @include bg('main-v2.1/bubble-chalk-2.png', top, center);
        padding: 27px 20px;
        margin-top: 40px;
    }
}

#homepage-aaposts {
    .post-title {
        font-size: em(24);
        height: 85px;
    }
}

//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> TRINITY                                              #
//                                                                             #
//##############################################################################

#homepage-trqualifications {
    .post-title {
        font-size: em(20);
        height: 74px;
    }
}

#homepage-subjects {
    .wrap-inner {
        height: 250px;
    }
}

//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> MEDIA                                                    #
//                                                                             #
//##############################################################################

.homepage--media {
    .split-post {
        .post-title {
            font-size: 1.625em;
        }
    }

    .sidebar-area {
        .block-item {
            max-width:322px;
        }
    }
}


.picker__header .picker__select--month {
  width: 35%;
}
.picker__header .picker__select--year {
  width: 22.5%;
}

.picker__header{
	.picker__select--month,
	.picker__select--year{
		background-color: white;
		border-color: #eee;
		height: auto;
	}
}

.picker__nav--prev:hover,
.picker__nav--next:hover {
  background: #eee;
}

.picker__input.picker__input--active{
	border: 1px solid $primarycolor;
	background-color: white;
}

.picker__holder:focus{
	outline: none;
}

.picker__day--today:before {
  border-top-color: $secondarycolor;
}

.picker__day--highlighted {
  border-color: $primarycolor;
}

.picker__day--infocus:hover,
.picker__day--outfocus:hover {
  background: $primarycolor;
  color: white;
}

.picker__day--highlighted:hover,
.picker--focused .picker__day--highlighted {
  background: white;
  color: black;
}

.picker__day--selected,
.picker__day--selected:hover,
.picker--focused .picker__day--selected {
  background: $primarycolor;
  color: white;
}

.picker__button--today:hover,
.picker__button--clear:hover,
.picker__button--close:hover {
  background: #eee;
  border-bottom-color: #eee;
}

.picker__button--today:before {
  border-top-color: $secondarycolor;
}
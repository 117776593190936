.r-tabs .r-tabs-nav {

}

.r-tabs .r-tabs-tab {

}

.r-tabs .r-tabs-panel {
    display: none;
}

.r-tabs .r-tabs-accordion-title {
    display: none;
}

.r-tabs .r-tabs-panel.r-tabs-state-active {
    display: block;
}

/* Accordion responsive breakpoint */
@media only screen and (max-width: 768px) {
    .r-tabs .r-tabs-nav {
        display: none;
    }

    .r-tabs .r-tabs-accordion-title {
        display: block;
    }
}
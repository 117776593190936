$visual-grid					: false !global;
$border-box-sizing				: false !global;
$max-width						: 78em !global; // 1248px
$gutter							: 0em !global;


$primarycolor 					: #d64b8b; //pink
$secondarycolor 				: #125553; //teal
$tertiarycolor 					: $primarycolor; //depreciated
$quaternarycolour				: $secondarycolor; //depreciated
$quinternarycolour      : #dd69a3; // just off-pink
$sexternarycolour       : #efbbd3; // lighter just off-pink
$septernarycolour       : #de6ea1; // even lighter just off-pink

$aa-primarycolor 				: #00aeef; //blue
$aa-secondarycolor 				: #ee4493; //pink
$aa-tertiarycolor 				: #7dc242; //green
$aa-quaternarycolour			: #ffd800; //yellow

$aagold							: #00bfab; //green
$aasilver						: #e7272a; //red
$aabronze						: #af79b4; //violet
$aaexplore						: #00b095; //green
$aadiscover						: #f58220; //orange

$aa-list						: (gold: $aagold, silver: $aasilver, bronze: $aabronze, explore: $aaexplore, discover: $aadiscover);

$tr-primarycolor 				: #6A265F; //purple
$tr-secondarycolor 				: #a471ac; //lighter purple

$neutralcolor 					: #666;
$midneutralcolor 				: darken($neutralcolor, 12%);
$darkneutralcolor 				: darken($neutralcolor, 24%);
$lightneutralcolor 				: lighten($neutralcolor, 10%);
$lighterneutralcolor 			: lighten($neutralcolor, 20%);
$lightestneutralcolor 			: lighten($neutralcolor, 45%);

$black 							: rgb(0,0,0);
$white 							: rgb(255,255,255);

$lightblack         : lighten($black, 40%);

$bodycolor 						: $white;
$textcolor 						: $black;

$linkcolor 		    			: $primarycolor;
$linkcolorhover 				: $linkcolor;
$linkcolorvisited   			: $linkcolor;
$linkcolorfocus 				: darken($linkcolor, 10%);

$alertcolor 					: #da4f49; //red
$successcolor 					: $secondarycolor; //teal


$fb								: #4267b2; //facebook blue
$ig								: #125688; //instagram blue
$tw								: #52bbec; //twitter blue
$yt								: #cc181e; //youtube red
$gg								: #4285f4; //google blue


$basefontfamily 				: Arial, $font-stack-helvetica;
$basefontsize 					: 16;
$baselineheight 				: 1.5;

$baseheadingfont 				: 'Roboto', $font-stack-helvetica;
$baseheadingfontweight 			: normal;
$baseheadingfontcolor 			: $textcolor;

$altfontfamily 					: 'bebas_neue_regularregular', 'BebasNeue', $font-stack-helvetica;


$bordercolor    				: #a7a9ac;
$borderstyle					: solid;
$borderwidth 					: 1px;
$border							: $borderwidth $borderstyle $bordercolor;

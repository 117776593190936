#cookielaw,
.nav-toggle,
.alert,
.header-image,
.owl-carousel,
.owl-carousel.owl-loaded,
#title-bar,
.pagination,
.block-form,
.block-aside,
.ad-block,
.footer-links,
.block-authormore,
#comment-form,
.post-footer .post-cta,
.fluid-width-video-wrapper,
#footer,
#noti{
    display: none !important;
}

.post-meta a{
    text-decoration: none;
}

#logo-icon a[href]:after,
.icon a[href]:after,
.post-meta a[href]:after{
    content: "";
    text-decoration: none;
}

#nav, .nav-funcs,
.nav-funcs li.icon{
    display: block;
}

.nav-primary,
.nav-funcs li{
    display: none;
}

#header{
    background: transparent !important;
}

#header .header-title{
    position: static;
    text-align: left;
    padding: 0 20px;
}

img {
    width: auto !important;
}

.image-profile img {
    width: 70px !important;
    height: 70px !important;
    max-width: none !important;
}

.aa-logo {
    a:after {
        content: "" !important;
    }

    img {
        width: 118px !important;
    }
}

#header .header-text {
    position: static;
}

.listing-image-grid{

    li{
        margin-bottom: 1em;
    }

    .post-img{
        width: 200px;
    }

    .post-title,
    .post-author,
    .post-date,
    .post-comments,
    .post-info{
        position: static !important;
        padding: 0;
        display: block;
    }

    .post-title{
        height: auto;
        padding: 0 !important;
    }
}

.post-img{
    background: transparent !important;
}

.post-type {
    position: static !important;
    display: block !important;
    padding: 0 !important;
    background: transparent !important;
}

.content-list {
    li {
        border-bottom: 1px solid black;

        img {
            max-width: 200px !important;
        }
    }
}

.content-info-side,
.content-info-full{
    .img{
        width: 200px;
    }
}

////////////////////////////////
// PHOTO BLOG

.owl-controls,
.slideshow-carousel-nav-cont {
    display: none !important;
}

.owl-wrapper-outer {
    height: auto !important;
}

.owl-wrapper {
    width: auto !important;
}

.owl-carousel .owl-item {
    float: none;
    margin-bottom: 20px;
}
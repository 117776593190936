.old_browser{
    margin: 0;
    background: red;
    color: white;
    padding: 0.2em 0.5em;
	text-align: center;
	font-weight: bold;

	a{
		color: white;
	}
}

body{
	font-family: $basefontfamily;
	background: $bodycolor;
	color: $textcolor;
	margin: 0;
	padding: 0;
	line-height: $baselineheight;
}

iframe{
	width: 100%;
	border: 0;
	margin: 0;
	padding: 0;
}

h1,h2,h3,h4,h5,h6{
	font-family: $baseheadingfont;
	font-weight: $baseheadingfontweight;
	color: $baseheadingfontcolor;
}

p{
	font-size: em($basefontsize);
}

a{
	color: $linkcolor;
	text-decoration: none;
}

a:hover{
	color: $linkcolorhover;
}

a:active,
a:focus{
	color: $linkcolorfocus;
}

.button-main{
	
	transition: all 0.5s;
	
	&:hover{
		
	}
}

code{
	padding: 4px;
	font-size: 90%;
	color: $white;
	background: $secondarycolor;
	border-radius: 4px;
	white-space: nowrap;
	hyphens: none;
}
//##############################################################################
//                                                                             #
//	! 1.2 GLOBAL -> HEADER                                                     #
//                                                                             #
//##############################################################################

#header{
	#logo-full{
		top: 60px;
	}

	.owl-item{
		.item-info{
			bottom: 50px;
		}
	}

	.owl-dots{
		bottom: 12px;
	}
}




//##############################################################################
//                                                                             #
//	! 1.3 GLOBAL -> FOOTER                                                     #
//                                                                             #
//##############################################################################




//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> BLOCKS                                                    #
//                                                                             #
//##############################################################################

.block-about{
	li{
		b,
		.whatis,
		.viewposts{
			float: left;
			width: 35%;
		}

		b{
			width: 38%;
		}

		.viewposts{
			width: 27%;
			text-align: right;
			clear: none;
		}
	}
}

.block-highlight{
	.item-thumb{
		img{
			width: 140px;
			height: 140px;
		}
	}
}

.block-authormore{
	&.post-count-3{
		.t3{
			display: block;
		}

		.more-posts{
			display: none;
		}

		.has-more-posts{
			display: inline-block;
		}
	}
}




//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> LISTING                                                   #
//                                                                             #
//##############################################################################

.content-list.listing-image-grid{
	li{
		@include span-columns(4);
	}

	.i2{
		.post-cont{
			&:after{
				content: "";
			}
		}
	}

	.i3{
		.post-cont{
			&:after{
				content: none;
			}
		}
	}

	.post-title{
		height: 76px;
		font-size: em(20);
	}
}

.listing-image-grid{
	&.no-footer{
		.post-title{
			height: 98px;
		}
	}
}

.page--error{
	.content-list{
		.t4{
			display: none;
		}
	}
}




//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> ARTS AWARD                                               #
//                                                                             #
//##############################################################################

#homepage-hubs{
	.wrap-inner{
		height: 190px;
	}
}

#homepage-aainfo{
	.aainfo-text-2 .aainfo-bubble{
		padding: 36px 20px;
	}
}

#homepage-users{
	.post-type{
		max-width: none;
	}
}

//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> TRINITY                                              #
//                                                                             #
//##############################################################################

#homepage-subjects{
	.wrap-inner{
		height: 210px;
	}
}

//##############################################################################
//                                                                             #
//	! 1.2 GLOBAL -> HEADER                                                     #
//                                                                             #
//##############################################################################

#header{
	.header-title{
		font-size: em(42);
	}
}




//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> BLOCKS                                                    #
//                                                                             #
//##############################################################################

.block-about{
	li{
		b,
		.whatis,
		.viewposts{
			float: left;
			width: 35%;
		}
		
		b{
			width: 38%;
		}
		
		.viewposts{
			width: 27%;
			text-align: right;
			clear: none;
		}
	}
}

.block-voiceboxes{
	.block-title{
		span{
			display: inline;
		}
	}
}

.block-user{
	.item-thumb{
		img{
			width: 140px;
			height: 140px;
		}
	}
}

.block-author{
	.image-profile{
		img{
			width: 140px;
			height: 140px;
		}
	}
}





//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> LISTING                                                   #
//                                                                             #
//##############################################################################

.listing-image-grid{
	.post-title{
		height: 97px;
		font-size: em(26);
	}
	
	&.no-footer{
		.post-title{
			height: 158px;
		}
	}
	
	.type-theme{
		a{
			font-size: em(18);
		}
	}
}




//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> SLIDESHOW                                                 #
//                                                                             #
//##############################################################################

.slideshow-carousel-nav-cont{
	display: block;
}




//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> ARTS AWARD                                               #
//                                                                             #
//##############################################################################

#homepage-aainfo{	
	.aainfo-video{
		span{
			b{
				font-size: em(18);
			}
		}
		
		.fa{
			font-size: em(75);
		}
	}
}

//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> TRINITY                                               #
//                                                                             #
//##############################################################################

.templateTR {
    #feature-boxes {
        .post-title {
            font-size: em(26);
        }
    }
}

#homepage-trqualifications {
    .post-title {
        font-size: em(26); //16
        height: 216px;
    }

    .post-link_text {
        height: 14px;
        font-size: 1em;
    }
}

#homepage-subjects {

    .item-wrap {
        @include span-columns(12);

    }

    .item-info {
        position: relative;
        margin-top: 20px;
        bottom: inherit;
    }

    .wrap-inner {
        /*border-right: 1px solid #fff;*/
        height: auto;
    }

    .subject-bronze,
    .subject-gold {
        .wrap-inner {
            border-right: none;
        }
    }
}
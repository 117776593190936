//##############################################################################
//                                                                             #
//	! 1.2 GLOBAL -> HEADER                                                     #
//                                                                             #
//##############################################################################

#header {
    .banner-image {
        height: 220px;

        img {
            height: 220px;
            margin-left: (-917px / 2);
        }
    }

    .header-title {
        font-size: em(54);
    }

    &.carousel-artsaward {
        .owl-item {
            height: 220px;

            img {
                position: absolute;
                height: 220px;
                margin-left: (-917px / 2);
            }
        }
    }

    .owl-item {
        height: auto;

        img {
            position: static;
            width: 100%;
            height: auto;
            margin-left: 0;
        }

        .item-info {
            width: 80%;
            left: 10%;

            h2 {
                font-size: em(22);
            }

            p {
                font-size: em(14);
            }
        }
    }

    .owl-prev,
    .owl-next {
        display: block;
    }
}


//##############################################################################
//                                                                             #
//	! 1.3 GLOBAL -> FOOTER                                                     #
//                                                                             #
//##############################################################################

#footer {

    .footer-logo {
        @include span-columns(12);
        padding: 0 0 30px 0;

        img {
            width: auto;
            max-width: 100%;
            max-height: 200px;
        }
    }

    .site-list {
        @include span-columns(4);
    }

    .footer-support-links {
        @include span-columns(12);

        .support,
        .friend {
            @include span-columns(4);
        }
    }
}

//##############################################################################
//                                                                             #
//	! 3 CONTENT -> HUB                                                         #
//                                                                             #
//##############################################################################

.hub-intro {
    .hub-intro-links {
        a {
            font-size: em(22);
        }
    }
}

.menu__bubbles {
    display: table;

    li {
        margin-bottom: 0;
        display: table-cell;
        width: 19%;
        vertical-align: middle;
    }

    a {
        position: relative;
    }

    .bubble__bg {
        width: 100%;
        height: auto;

        img {
            opacity: 0.4;
        }
    }

    .bubble__text {
        position: absolute;
        top: 20px;
        right: 25px;
        bottom: 20px;
        left: 25px;
        padding: 0px;
        text-align: center;

        .text__wrap--outer {
            display: table;
            width: 100%;
            height: 100%;
        }

        .text__wrap--inner {
            display: table-cell;
            vertical-align: middle;
        }

        .bubble__title,
        .bubble__subtitle {
            font-weight: bold;
            transition: all .4s;
        }

        .bubble__title {
            font-size: 20px;
        }

        .bubble__subtitle {
            color: $midneutralcolor;
            font-size: 10px;
        }

        .fa {
            display: none;
        }
    }

    .r-tabs-state-active {
        width: 24%;

        .bubble__text {
            .bubble__title {
                font-size: 25px;
            }

            .bubble__subtitle {
                font-size: 11px;
            }
        }
    }

    .bubbles__spacer {
        display: table-cell;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> ARTFORM ICONS                                             #
//                                                                             #
//##############################################################################

#menu-artform {
    li {
        width: 20%;
        border-right: 1px solid $lightestneutralcolor;

        &:nth-child(3n+1) {
            border-right: 1px solid $lightestneutralcolor;
        }

        &.i5,
        &.i10 {
            border-right: 0;
        }

        &.i5 {
            border-top: 0;
        }
    }

    .explore {
        width: 20%;
        height: 88px;
        padding: 0;
        padding-top: (((88 - 49) / 2) - 2 * 1px);
        text-align: center;

        b {
            width: 96px;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> BLOCKS                                                    #
//                                                                             #
//##############################################################################

.block-profile {
    .profile-social {
        margin-bottom: 15px;

        a {
            display: inline-block;
            margin-right: 30px;

            .fa {
                width: auto;
                margin-right: 3px;
            }
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> LISTING                                                   #
//                                                                             #
//##############################################################################

.listing-image-grid {
    .post-title {
        height: 97px;
        font-size: em(26);
    }

    &.no-footer {
        .post-title {
            height: 158px;
        }
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> NOTI                                                      #
//                                                                             #
//##############################################################################

.noti {
    bottom: 20px;
    right: -420px;
    width: 400px;
    border-radius: 2px;

    &.show {
        right: 20px;
        bottom: 20px;
    }

    .noti-close {
        top: 4px;
        right: 4px;
        font-size: em(28);
        padding: 0 10px 5px;
    }

    h2 {
        font-size: em(16);
        line-height: 41px;
    }

    .noti-layout {
        margin-top: 6px;
    }

    h3 {
        font-size: em(18);
        margin-bottom: 4px;
    }

    .noti-author {
        font-size: em(13);
    }

    .noti-img {
        width: 80px;
    }

    .noti-cta {
        border-radius: 0 0 2px 2px;
        font-size: em(14);
        padding: 6px 0;
    }
}


//##############################################################################
//                                                                             #
//	! 4 COMPONENT -> TOAST                                                     #
//                                                                             #
//##############################################################################

.toast {
    &.toast--onlymobile {
        display: none;
    }
}


//##############################################################################
//                                                                             #
//	! 5.1 HOMEPAGE -> VOICE                                                    #
//                                                                             #
//##############################################################################

.item-feature {
    .item-thumb a .fa {
        font-size: 4em;
    }

    h3 {
        font-size: em(24);
    }
}

#from-community {
    .item-summary {
        max-width: 400px;
    }
}

#featured-voiceboxes .block-voiceboxes {
    .item-title {
        display: flex;
        align-items: flex-end;

        h3 {
            line-height: 1;
        }
    }
}

.tickets {
    font-size: 1em;

    .show-listings {
        height: 225px;

        .show-item {
            width: 150px;

            .show-img .show-price {
                font-size: 1em;
            }
        }
    }
}

.row-user {
    @include span-columns(12);
    display: flex;
    align-items: stretch;
}

#featured-user {
    border-right: $border;
}

#featured-user,
#space-homepage {
    flex: 0 0 50%;
}

#space-homepage {
    .space-small {
        display: none;
    }

    .space-medium {
        display: block;
    }
}


//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> ARTS AWARD                                               #
//                                                                             #
//##############################################################################

#homepage-intro {
    .intro-wrap {
        display: table;
    }

    .intro-img {
        display: table-cell;
        vertical-align: top;

        img {
            width: 140px;
            height: 140px;
            background: #fff;
            box-shadow: 2px 2px 10px $neutralcolor;
        }
    }

    .intro-text {
        display: table-cell;
        vertical-align: top;
        padding-left: 20px;
    }

    h2 {
        font-size: em(30);
    }

    p {
        font-size: em(16);
    }
}

#homepage-hubs {
    .item-title {
        font-size: em(50);
    }

    .hub-link {
        top: 35px;
    }
}

#homepage-aainfo {
    .aainfo-video {
        span {
            b {
                font-size: em(20);
            }
        }

        .fa {
            font-size: em(100);
        }
    }
}

//##############################################################################
//                                                                             #
//	! 5.2 HOMEPAGE -> TRINITY                                              #
//                                                                             #
//##############################################################################

.templateTR {
    #feature-boxes {
        .post-title {
            font-size: em(26);
        }
    }
}

#homepage-subjects {
    /*.item-title {
        font-size: em(50);
    }*/

    .subject-link {
        top: 35px;
    }
}
